import React, { useCallback, useState } from 'react';
import { compose } from 'recompose';
import { PropTypes } from 'prop-types';
import { connect, FelaComponent } from 'react-fela';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import { intlShape } from 'react-intl';
import { Rnd } from 'react-rnd';
import { isEqual } from 'lodash';
import config from '../../../config';
import { container, image, title, icon as iconStyle } from './Icon.style';
import DesktopSubMenu from '../DesktopSubMenu';
import ClickAlerter from '../../ClickAlerter';

import iconDeleting from '../../../images/icons/deleting.gif';
import calculateSubmenuPosition from '../../../utils/calculateSubmenuPosition';

const Icon = ({
    styles,
    icon,
    titleKey,
    intl,
    detail,
    onDragStop,
    isPlaceAvailable,
    dragGrid,
    openWindow,
    items,
    onSelect,
    isDeleting,
    disableDragging,
}) => {
    const [isDragging, setDragging] = useState(false);
    const [isFocused, setFocus] = useState(false);
    const [isOnRejectedPos, setOnRejectedPos] = useState(false);
    const [subMenuPosition, setSubMenuPosition] = useState(false);

    return (
        <>
            <FelaComponent
                style={iconStyle}
                isDragging={isDragging}
                isSubMenuOpened={!!subMenuPosition}
                isOnRejectedPos={isOnRejectedPos}
            >
                {({ className }) => (
                    <Rnd
                        {...{
                            size: {
                                width: config.icons.width,
                                height: config.icons.height,
                            },
                            bounds: 'parent',
                            position: detail.position,
                            enableResizing: false,
                            disableDragging: disableDragging || isDeleting,
                            onDragStop: (e, pos) => {
                                setDragging(false);
                                setOnRejectedPos(false);
                                return onDragStop(e, pos);
                            },
                            onDrag: (e, pos) => {
                                setDragging(true);
                                /* Old position */
                                if (isEqual({ x: pos.x, y: pos.y }, detail.position)) {
                                    setOnRejectedPos(false);
                                } else {
                                    setOnRejectedPos(!isPlaceAvailable(e, pos, titleKey));
                                }
                            },
                            className,
                            ...(dragGrid && { dragGrid }),
                        }}
                    >
                        <ClickAlerter
                            onOutsideClick={() => {
                                setSubMenuPosition(false);
                                setFocus(false);
                            }}
                            onInsideClick={e => {
                                if (isFocused) {
                                    openWindow(e);
                                }
                                setFocus(true);
                                e.preventDefault();
                            }}
                            deps={[subMenuPosition]}
                            onContextMenu={e => {
                                e.preventDefault();
                                if (subMenuPosition) {
                                    setSubMenuPosition(false);
                                } else {
                                    const pos = calculateSubmenuPosition(e.clientX, e.clientY);
                                    const rect = e.target.getBoundingClientRect();
                                    setSubMenuPosition({
                                        x: pos.x - rect.left + 20,
                                        y: pos.y - rect.top + 45,
                                    });
                                }
                            }}
                        >
                            <>
                                {subMenuPosition && (
                                    <DesktopSubMenu
                                        position={subMenuPosition}
                                        onChange={(e, item) => {
                                            onSelect(e, item);
                                            setSubMenuPosition(false);
                                        }}
                                        items={items}
                                    />
                                )}
                                {isDeleting ? (
                                    <div className={styles.container}>
                                        <img
                                            src={`${iconDeleting}?${Math.random()}`}
                                            alt="Deleting"
                                            className={styles.image}
                                        />
                                    </div>
                                ) : (
                                    <button type="button" className={styles.container}>
                                        <img
                                            src={icon}
                                            alt={intl.formatMessage({ id: titleKey })}
                                            className={styles.image}
                                        />
                                        <FelaComponent style={title} isFocused={isFocused}>
                                            <FormattedMessage id={titleKey} />
                                        </FelaComponent>
                                    </button>
                                )}
                            </>
                        </ClickAlerter>
                    </Rnd>
                )}
            </FelaComponent>
        </>
    );
};

Icon.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    icon: PropTypes.node.isRequired,
    titleKey: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    detail: PropTypes.shape({
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
    }).isRequired,
    isPlaceAvailable: PropTypes.func.isRequired,
    onDragStop: PropTypes.func.isRequired,
    dragGrid: PropTypes.arrayOf(PropTypes.number),
    items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
    onSelect: PropTypes.func.isRequired,
    openWindow: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool,
    disableDragging: PropTypes.bool,
};

Icon.defaultProps = {
    dragGrid: undefined,
    isDeleting: false,
    disableDragging: false,
};

export default compose(connect({ container, image, title }), injectIntl)(Icon);
