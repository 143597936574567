import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { DIALOG_INFO } from '../constants/DialogTypes';

const initState = {
    dialogActive: false,
    dialogText: '',
    dialogType: DIALOG_INFO,
    dialogSuccess: () => {},
    dialogCancel: () => {},
    dialogClose: () => {},
    dialogTimer: undefined,
};

export const DialogContext = React.createContext(initState);

export const DialogProvider = ({ children }) => {
    const [dialog, setDialog] = useState(initState);
    const handleOpenDialog = useCallback(
        (dialogType, dialogText, dialogSuccess, dialogCancel, dialogClose, dialogTimer) => {
            setDialog({
                dialogActive: true,
                dialogType,
                dialogText,
                dialogSuccess,
                dialogCancel,
                dialogClose: () => {
                    if (dialogClose) {
                        dialogClose();
                    }
                    setDialog(initState);
                },
                dialogTimer,
            });
        },
        [],
    );

    return (
        <DialogContext.Provider
            value={{
                openDialog: handleOpenDialog,
                ...dialog,
            }}
        >
            {children}
        </DialogContext.Provider>
    );
};

DialogProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const DialogConsumer = DialogContext.Consumer;
