import React, { useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import Icon from './Icon';
import { ICON_REMOVE_ANIMATION_TIME } from '../../../constants/animations';

const MENU_ITEMS = [{ id: 'desktop.settings.icon.open' }, { id: 'desktop.settings.icon.delete' }];

const DesktopIcon = ({ openWindow, removeIcon, ...props }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const handleSelectSubMenuItem = useCallback(
        (e, item) => {
            if (item === MENU_ITEMS[0]) {
                openWindow(e);
            } else if (item === MENU_ITEMS[1]) {
                setIsDeleting(true);
                setTimeout(removeIcon, ICON_REMOVE_ANIMATION_TIME);
            }
        },
        [openWindow, removeIcon],
    );
    return (
        <Icon
            isDeleting={isDeleting}
            items={MENU_ITEMS}
            onSelect={handleSelectSubMenuItem}
            openWindow={openWindow}
            {...props}
        />
    );
};

DesktopIcon.propTypes = {
    openWindow: PropTypes.func.isRequired,
    removeIcon: PropTypes.func.isRequired,
};

export default DesktopIcon;
