import subsetUni0563 from './subset-Uni0563';
import GreycliffCFRegular from './GreycliffCF-Regular.ttf';
import GreycliffCFMedium from './GreycliffCF-Medium.ttf';
import GreycliffCFBold from './GreycliffCF-Bold.ttf';
import GreycliffCFExtraBold from './GreycliffCF-ExtraBold.ttf';
import GreycliffCFDemiBold from './GreycliffCF-DemiBold.ttf';

export default [
    {
        name: 'Greycliff',
        files: [GreycliffCFRegular],
        options: {
            fontStyle: 'normal',
            fontWeight: '300',
            fontDisplay: 'swap',
        },
    },
    {
        name: 'Greycliff',
        files: [GreycliffCFMedium],
        options: {
            fontStyle: 'normal',
            fontWeight: '500',
            fontDisplay: 'swap',
        },
    },
    {
        name: 'Greycliff',
        files: [GreycliffCFBold],
        options: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontDisplay: 'swap',
        },
    },

    {
        name: 'Greycliff',
        files: [GreycliffCFExtraBold],
        options: {
            fontStyle: 'normal',
            fontWeight: '800',
            fontDisplay: 'swap',
        },
    },
    {
        name: 'Greycliff',
        files: [GreycliffCFDemiBold],
        options: {
            fontStyle: 'normal',
            fontWeight: '600',
            fontDisplay: 'swap',
        },
    },
    {
        name: 'Pixel',
        files: [subsetUni0563],
        options: {
            fontStyle: 'normal',
            fontWeight: '300',
            fontDisplay: 'swap',
        },
    },
];
