import { useRef, useState, useCallback } from 'react';
import useEventListener from './useEventListener';

const initialSize = { width: 0, height: 0 };

export default () => {
    const [size, setSize] = useState(initialSize);
    const ref = useRef(null);

    const handleResize = useCallback(() => {
        const rect = ref.current && ref.current.getBoundingClientRect();
        setSize({ width: rect && rect.width, height: rect && rect.height });
    }, [ref]);

    useEventListener('resize', handleResize);
    return [size, ref];
};
