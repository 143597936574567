import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect as connectFela } from 'react-fela';
import * as stylesAll from './SplashScreen.style';
import ProgressBar from '../ProgressBar/ProgressBar';
import BehindTheGridLogo from '../BehindTheGridLogo';
import { PROGRESS_BAR_INCREMENT_TIME } from '../../constants/animations';

const calculateRandomValue = () => {
    return Math.round(Math.random() * 10);
};

const SplashScreen = ({ styles, onLoaded, withSystemInfo }) => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (percentage >= 100) {
                onLoaded();
            } else {
                const newValue = percentage + calculateRandomValue();
                setPercentage(newValue > 100 ? 100 : newValue);
            }
        }, PROGRESS_BAR_INCREMENT_TIME);
        return () => clearInterval(interval);
    }, [onLoaded, percentage]);

    return (
        <div className={styles.wrapper} id="splash-wrapper">
            <div className={styles.innerWrapper}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href="https://ackee.cz" target="_blank" className={styles.logoHref}>
                    <BehindTheGridLogo fill="white" className={styles.logo} />
                </a>
                {withSystemInfo && (
                    <div className={styles.systemInfo}>
                        <ul>
                            <li>Version –––––––––––––&gt; v1.00</li>
                            <li>Release date –––––––––&gt; 23/02/2019</li>
                            <li>Release type –––––––––&gt; Library</li>
                            <li>Kind –––––––––––––––&gt; Git</li>
                            <li>Format –––––––––––––&gt; xxx</li>
                            <li>Protection –––––––––––&gt; Open Source</li>
                            <li>Files N ––––––––––––––&gt; 49_items</li>
                        </ul>
                    </div>
                )}
                <div className={styles.progressWrapper}>
                    <ProgressBar percentage={percentage} />
                    <span className={styles.progressText}>{`${percentage}%`}</span>
                </div>
            </div>
        </div>
    );
};

SplashScreen.propTypes = {
    styles: PropTypes.shape({
        wrapper: PropTypes.string,
        logo: PropTypes.string,
        innerWrapper: PropTypes.string,
        progressText: PropTypes.string,
        systemInfo: PropTypes.string,
        progressWrapper: PropTypes.string,
        logoHref: PropTypes.string,
    }).isRequired,
    onLoaded: PropTypes.func.isRequired,
    withSystemInfo: PropTypes.bool,
};

SplashScreen.defaultProps = {
    withSystemInfo: false,
};

export default connectFela(stylesAll)(SplashScreen);
