const navBase = {
    listStyle: 'none',
    float: 'left',
    margin: 0,
};

const itemBase = {
    display: 'block',
    float: 'left',
    fontWeight: 700,
    margin: 0,
    fontSize: 16,
    userSelect: 'none',
};

export const navWrapper = ({ theme }) => ({
    height: '100%',
});
export const innerWrapper = ({ theme }) => ({
    float: 'left',
    overflow: 'auto',
});
export const nav = ({ theme }) => ({
    ...navBase,
    position: 'relative',
});
export const navRight = ({ teme }) => ({
    ...navBase,
    position: 'relative',
    float: 'right',
});
const menuPadding = {
    paddingTop: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 10,
    maxWidth767: {
        paddingLeft: 8,
        paddingRight: 8,
    },
};
const buttonBase = {
    backgroundColor: 'unset',
    cursor: 'pointer',
    border: 0,
    outline: 0,
    ...menuPadding,
};
export const button = ({ theme }) => ({
    ...buttonBase,
});
export const line = ({ theme }) => ({
    backgroundColor: theme.colors.white,
    height: 'calc(100% - 15px)',
    width: '100%',
    borderLeft: `3px solid ${theme.colors.blue}`,
});
export const noHoverItem = ({ theme, padding }) => {
    if (!padding) {
        return { ...itemBase };
    }
    return {
        ...itemBase,
        ...menuPadding,
    };
};

export const logo = ({ theme }) => ({
    padding: 0,
    display: 'block',
    float: 'left',
});

export const hideOnMobile = () => ({
    '@media(max-width: 870px)': {
        display: 'none',
    },
});
