export const container = ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    width: '100%',
    height: '100%',
});

export const element = ({ theme }) => ({
    width: 150,
    margin: '1rem',
    textAlign: 'center',
    fontSize: '0.9rem',
    border: 'none',
    background: 'none',
    display: 'block',
    textDecoration: 'none',
    color: theme.colors.black,
});

export const title = ({ theme }) => ({
    fontSize: 18,
    lineHeight: '22px',
    fontWeight: 600,
    color: theme.colors.black,
    margin: '16px 0 8px',
});
