import React from 'react';
import { PropTypes } from 'prop-types';
import { FelaComponent } from 'react-fela';

import ProjectThumbnail from './ProjectThumbnail';
import * as componentStyles from './Projects.styles';

const Projects = ({ data }) => (
    <FelaComponent style={componentStyles.container}>
        {data.map((project, i) => (
            <ProjectThumbnail {...{ ...project, key: i }} />
        ))}
    </FelaComponent>
);

Projects.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
};

Projects.defaultProps = {
    data: [],
};

export default Projects;
