import React from 'react';
import { compose } from 'recompose';
import { PropTypes } from 'prop-types';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';
import { connect } from 'react-fela';

import { iconButton } from './styles';

const IconButton = ({ styles, altId, icon, intl, iconClassName, ...props }) => (
    // reason: type should be in props
    // eslint-disable-next-line react/button-has-type
    <button {...{ className: styles.iconButton, ...props }}>
        <img className={iconClassName} src={icon} alt={intl.formatMessage({ id: altId })} />
    </button>
);

IconButton.propTypes = {
    altId: PropTypes.string,
    icon: PropTypes.node.isRequired,
    intl: intlShape.isRequired,
    iconClassName: PropTypes.string,
    styles: PropTypes.shape({
        iconButton: PropTypes.string.isRequired,
    }).isRequired,
};

IconButton.defaultProps = {
    altId: ' ',
    iconClassName: null,
};

export default compose(
    connect({ iconButton }),
    injectIntl,
)(IconButton);
