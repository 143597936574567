import { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import config from '../config';
import {
    updateIcon,
    reorderIcons,
    closeWindow,
    refreshSearchWindow,
    openWindow as openWindowAction,
    emptyBin as emptyBinAction,
} from '../actions/desktop';
import { BIN_ANIMATION_TIME } from '../constants/animations';

/**
 * Fix position if item is out of bound
 */
const fixOutOfBound = pos => {
    const newPos = { x: pos.x, y: pos.y };

    if (newPos.x < 0) {
        newPos.x = 0;
    }
    if (newPos.y < 0) {
        newPos.y = 0;
    }
    if (newPos.x > window.innerWidth) {
        newPos.x = window.innerWidth;
    }
    if (newPos.y > window.innerHeight - config.navbar.height) {
        newPos.y = window.innerHeight - config.navbar.height;
    }

    return newPos;
};

/**
 * In arrangeIcons function if we move with some icon to the left, right or up we call this function.
 * This function will detect if for entered icon there is a collision.
 * If yes, we take the older icon and move it it to another place and recursively call this function with that icon.
 * At the end all icons are on unique positions.
 *
 * @param curIcon
 * @param icons
 * @param val
 * @param cord
 */
const findNewPlace = (curIcon, icons, val, cord) => {
    icons.forEach(neighbourIcon => {
        // Ignore yourself
        if (neighbourIcon === curIcon) {
            return true;
        }
        // If position has not been initialized yet, ignore it
        if (!neighbourIcon.position) {
            return true;
        }
        if (isEqual(neighbourIcon.position, curIcon.position)) {
            neighbourIcon.position[cord] += val;
            if (neighbourIcon.position.x < 0) {
                neighbourIcon.position.y = 0;
                neighbourIcon.position.x += config.icons.width;
                return findNewPlace(neighbourIcon, icons, Math.abs(val), 'y');
            }
            if (neighbourIcon.position.y < 0) {
                neighbourIcon.position.x = 0;
                neighbourIcon.position.y += config.icons.height;
                return findNewPlace(neighbourIcon, icons, Math.abs(val), 'x');
            }
            return findNewPlace(neighbourIcon, icons, val, cord);
        }

        return true;
    });
};

const isPlaceForIconFree = (curPosition, icons, curIconKey, ignoreCollisionWithBin) => {
    ignoreCollisionWithBin = ignoreCollisionWithBin === true;

    // Icon was out of bound
    if (!isEqual(fixOutOfBound(curPosition), { x: curPosition.x, y: curPosition.y })) {
        return {
            collision: null,
            isFree: false,
        };
    }
    // If collision will be detected. We save that icon and return it
    let neighbourWithCollision = false;
    let isPlaceFree = icons.every(neighbourIcon => {
        if (curIconKey === neighbourIcon.titleKey || neighbourIcon.isDeleted) {
            return true;
        }
        if (isEqual(curPosition, neighbourIcon.position)) {
            neighbourWithCollision = neighbourIcon;
            return false;
        }
        /**
         * For every neighbour icon check if there is a collision.
         */
        if (
            Math.abs(curPosition.x - neighbourIcon.position.x) < config.icons.width &&
            Math.abs(curPosition.y - neighbourIcon.position.y) < config.icons.height
        ) {
            neighbourWithCollision = neighbourIcon;
            return false;
        }
        return true;
    });
    if (!isPlaceFree && ignoreCollisionWithBin) {
        isPlaceFree = neighbourWithCollision.dataId === 'bin';
    }
    return {
        collision: neighbourWithCollision,
        isFree: isPlaceFree,
    };
};

const arrangeIcons = icons => {
    const windowWidth = window.innerWidth - config.icons.width;
    const windowHeight = window.innerHeight - config.icons.height - config.navbar.height;

    let changeDetected = false;

    icons.forEach(icon => {
        /* If it is first time, set initial position */
        if (!icon.position) {
            icon.position = {
                x: icon.initPosition.x,
                y: icon.initPosition.y,
            };
            changeDetected = true;
        }

        /**
         * If X position out of parent window. Move it to the left. Size of the move is equal to icon width.
         * Repeat the action until all icons are on the right place
         */
        while (icon.position.x > windowWidth) {
            icon.position.x -= config.icons.width;
            findNewPlace(icon, icons, -config.icons.width, 'x');
            changeDetected = true;
        }
        /**
         * If Y position out of parent window. Move it up. Size of the move is equal to height of the icon.
         * Repeat the action until all icons are visible on screen
         */
        while (icon.position.y > windowHeight) {
            icon.position.y -= config.icons.height;
            findNewPlace(icon, icons, -config.icons.height, 'y');
            changeDetected = true;
        }
        return true;
    });
    return changeDetected ? icons : false;
};

const useDesktopIcon = (dispatch, icons) => {
    const isPlaceAvailable = useCallback(
        (e, pos, titleKey) => {
            return isPlaceForIconFree({ x: pos.x, y: pos.y }, icons, titleKey, true).isFree;
        },
        [icons],
    );
    const removeIcon = useCallback(
        (index, dataId) => {
            dispatch(closeWindow(dataId));
            dispatch(updateIcon(index, 'isDeleted', true));
            // remove data from search window (if opened)
            dispatch(refreshSearchWindow());
        },
        [dispatch],
    );
    const onDragStop = useCallback(
        (e, pos, index, gridEnabled) => {
            /* Fix boundaries */
            let { x, y } = fixOutOfBound(pos);
            if (gridEnabled) {
                /* Fix grid by calculating which position should icon snap */
                const mods = { x: x % config.icons.width, y: y % config.icons.height };
                if (mods.x !== 0) {
                    x =
                        mods.x < config.icons.width / 2
                            ? Math.floor(x / config.icons.width) * config.icons.width
                            : Math.ceil(x / config.icons.width) * config.icons.width;
                }
                if (mods.y !== 0) {
                    y =
                        mods.y < config.icons.height / 2
                            ? Math.floor(y / config.icons.height) * config.icons.height
                            : Math.ceil(y / config.icons.height) * config.icons.height;
                }

                if (x + config.icons.width > window.innerWidth) x -= config.icons.width; // prevent go out of X bound
                if (y + config.icons.height > window.innerHeight) y -= config.icons.height; // prevent go out of Y bound
            }

            const tryPlaceIcon = isPlaceForIconFree({ x, y }, icons, icons[index].titleKey, false);
            if (!tryPlaceIcon.isFree) {
                // Remove icon
                if (tryPlaceIcon.collision && tryPlaceIcon.collision.dataId === 'bin') {
                    removeIcon(index, icons[index].dataId);
                    return false;
                }
                if (window.navigator.vibrate) {
                    window.navigator.vibrate(200);
                }
                return dispatch(updateIcon(index, 'position', icons[index].position));
            }
            return dispatch(updateIcon(index, 'position', { x, y }));
        },
        [dispatch, icons, removeIcon],
    );

    const repairIconsGrid = useCallback(() => {
        icons.forEach((icon, index) => {
            onDragStop(
                null,
                {
                    x: icon.position.x,
                    y: icon.position.y,
                },
                index,
                true,
            );
        });
    }, [icons, onDragStop]);

    const openWindow = useCallback(
        (index, dataId, titleKey, x, y) => {
            dispatch(
                openWindowAction({
                    dataId,
                    titleKey,
                    x,
                    y,
                    type: icons[index].type,
                    isMinimized: false,
                }),
            );
        },
        [dispatch, icons],
    );

    const [isBinAnimating, setIsBinAnimating] = useState(false);

    const emptyBin = useCallback(() => {
        setIsBinAnimating(true);
        dispatch(emptyBinAction());
        setTimeout(() => {
            setIsBinAnimating(false);
        }, BIN_ANIMATION_TIME);
    }, [dispatch, setIsBinAnimating]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= config.icons.width) {
                return false;
            }

            const newIcons = arrangeIcons([...icons]);
            // Update only if change occurs (performance)
            if (newIcons) {
                return dispatch(reorderIcons(newIcons));
            }
            return false;
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [dispatch, icons]);

    return {
        onDragStop,
        isPlaceAvailable,
        repairIconsGrid,
        removeIcon,
        openWindow,
        isBinAnimating,
        emptyBin,
    };
};

export default useDesktopIcon;
