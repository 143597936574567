import React from 'react';
import { noop } from 'lodash';
import * as desktopActions from '../actions/desktop';
import * as systemActions from '../actions/system';
import navbarData from '../components/Header/Navbar.data';
import desktopData from '../components/Desktop/Desktop.data';
import { DIALOG_INFO, DIALOG_SHARE } from '../constants/DialogTypes';
import { disableFullScreen, enableFullScreen, isInFullscreen } from '../components/Header/FullScreen';

const findWindowData = dataId => {
    const windowIndex = desktopData.findIndex(item => item.dataId === dataId);
    return {
        index: windowIndex,
        dataId: desktopData[windowIndex].dataId,
        titleKey: desktopData[windowIndex].titleKey,
        type: desktopData[windowIndex].type,
        isMinimized: false,
    };
};

export const MENU_ITEMS = {
    'header.titles.ackee': [
        {
            id: 'header.nav.ackee.about',
            action: desktopDispatch => desktopDispatch(desktopActions.openWindowInCenter(navbarData.ABOUT)),
        },
        {
            id: 'header.nav.ackee.web',
            action: () => window.open('https://www.ackee.cz/en'),
        },
        {
            id: 'header.nav.ackee.openSource',
            action: desktopDispatch => desktopDispatch(desktopActions.openWindowInCenter(findWindowData('openSource'))),
        },
    ],
    'header.titles.file': [
        {
            id: 'header.nav.file.closeAll',
            action: desktopDispatch => desktopDispatch(desktopActions.closeAllWindows()),
        },
        {
            id: 'header.nav.file.search',
            action: desktopDispatch => desktopDispatch(desktopActions.openWindowInCenter(navbarData.SEARCH)),
        },
        {
            id: 'header.nav.file.share',
            withDialog: true,
            action: (_desktopDispatch, _systemDispatch, openDialog) => {
                return openDialog(DIALOG_SHARE, 'dialog.share');
            },
        },
    ],
    'header.titles.edit': [
        {
            id: 'header.nav.edit.career',
            action: desktopDispatch => desktopDispatch(desktopActions.openWindowInCenter(navbarData.CAREER)),
        },
    ],
    'header.titles.view': [
        {
            id: 'header.nav.edit.fullScreen',
            action: () => (isInFullscreen() ? disableFullScreen() : enableFullScreen()),
        },
        {
            id: 'header.nav.edit.instagram',
            action: () => window.open('https://www.instagram.com/ackeecz'),
        },
        {
            id: 'header.nav.edit.facebook',
            action: () => window.open('https://www.facebook.com/AckeeCZ'),
        },
    ],
    'header.titles.special': [
        {
            id: 'header.nav.special.ackeePhotos',
            action: desktopDispatch => desktopDispatch(desktopActions.openWindowInCenter(navbarData.GALLERY)),
        },
        {
            id: 'header.nav.special.blog',
            action: () => window.open('https://www.ackee.cz/blog'),
        },
        {
            id: 'header.nav.special.restart',
            withDialog: true,
            action: (_, systemDispatch, openDialog) => {
                const successCallback = () => systemDispatch(systemActions.setPlannedReboot());
                return openDialog(DIALOG_INFO, 'dialog.reboot', successCallback, noop, noop, 59);
            },
        },
        {
            id: 'header.nav.special.shutdown',
            withDialog: true,
            action: (_, systemDispatch, openDialog) => {
                const successCallback = () => systemDispatch(systemActions.setPlannedShutdown());
                return openDialog(DIALOG_INFO, 'dialog.shutdown', successCallback, noop, noop, 59);
            },
        },
    ],
};

export const MENU_ITEMS_KEYS = Object.keys(MENU_ITEMS);
