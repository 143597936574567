import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import searchIcon from '../../../images/search.svg';
import * as desktopActions from '../../../actions/desktop';
import navbarData from '../Navbar.data';

const SearchButton = ({ className, desktopDispatch }) => {

    const handleOpenWindow = useCallback(() => {
        desktopDispatch(
            desktopActions.openWindowInCenter({
                ...navbarData.SEARCH,
                // we will fill data later in SearchInput component
                data: [],
            }),
        )
    }, [desktopDispatch]);

    return (
        <button
            type="button"
            className={className}
            onClick={handleOpenWindow}
        >
            <img src={searchIcon} alt="Search icon" />
        </button>
    );
};

SearchButton.propTypes = {
    className: PropTypes.string,
    desktopDispatch: PropTypes.func.isRequired,
};

SearchButton.defaultProps = {
    className: undefined,
};

export default SearchButton;
