import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import Icon from './Icon';

import iconBinNonEmpty from '../../../images/icons/bin_full.svg';
import iconBinDeleting from '../../../images/icons/binDeleting.gif';
import { DIALOG_WARNING } from '../../../constants/DialogTypes';

const BIN_MENU_ITEMS = [{ id: 'desktop.settings.icon.open' }, { id: 'desktop.settings.icon.empty' }];

const BinIcon = ({ openWindow, emptyBin, isDeleting, openDialog, isFilled, icon, ...props }) => {
    const openWindowWithComponent = useCallback(e => openWindow(e), [emptyBin]);

    const handleSelectSubMenuItem = useCallback(
        (e, item) => {
            if (item === BIN_MENU_ITEMS[0]) {
                openWindowWithComponent(e);
            } else if (item === BIN_MENU_ITEMS[1]) {
                openDialog(DIALOG_WARNING, 'dialog.emptyBin', emptyBin);
            }
        },
        [emptyBin, openDialog, openWindow, openWindowWithComponent],
    );

    if (isDeleting) {
        icon = iconBinDeleting;
    } else if (isFilled) {
        icon = iconBinNonEmpty;
    }

    return (
        <Icon
            disableDragging={isDeleting}
            items={BIN_MENU_ITEMS}
            onSelect={handleSelectSubMenuItem}
            openWindow={openWindowWithComponent}
            icon={icon}
            {...props}
        />
    );
};

BinIcon.propTypes = {
    openWindow: PropTypes.func.isRequired,
    emptyBin: PropTypes.func.isRequired,
    openDialog: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
    isFilled: PropTypes.bool.isRequired,
    isDeleting: PropTypes.bool.isRequired,
};

export default BinIcon;
