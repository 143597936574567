import React from 'react';

const height = 28;

export const inner = ({ theme, borderColor }) => ({
    position: 'relative',
    display: 'block',
    float: 'left',
    marginRight: 5,
    border: 'none',
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    borderColor,
    fontSize: 13,
    paddingBottom: 5,
    fontWeight: 'bold',
    height,
    paddingLeft: 20,
    paddingRight: 20,
    color: borderColor,
    background: 'transparent',
    '>span': {
        display: 'block',
        marginTop: -3,
    }
});
const dotBase = borderColor => ({
    width: 2,
    height: 2,
    backgroundColor: borderColor || 'black',
});
export const dotLeft1 = ({ theme }) => ({
    ...dotBase(),
    marginLeft: 4,
});
export const dotLeft2 = ({ theme }) => ({
    ...dotBase(),
    marginLeft: 2,
});
export const dotLeft3 = ({ theme }) => ({
    ...dotBase(),
    height: 16,
});
export const dotRight3 = ({ theme }) => ({
    ...dotBase(),
    marginLeft: 6,
    height: 16,
});

const baseSide = {
    position: 'absolute',
    height: 24,
    top: 0,
};

export const left = ({ theme, borderColor }) => {
    return {
        ...baseSide,
        left: -6,
        '> div': {
            backgroundColor: borderColor || theme.colors.black,
        },
    };
};
export const right = ({ theme, borderColor }) => ({
    ...baseSide,
    right: -6,
    '> div': {
        backgroundColor: borderColor || theme.colors.black,
    },
});
