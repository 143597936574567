const height = 28;
const sizeWidth = 8;

const base = {
    content: '" "',
    position: 'absolute',
};
export const inner = ({ theme }) => ({
    position: 'relative',
    display: 'block',
    float: 'left',
    marginRight: 30,
    border: 'none',
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    fontSize: 12,
    lineHeight: '23px',
    fontWeight: 'bold',
    height,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.colors.black,
});
export const dotBase = () => ({
    width: 2,
    height: 2,
    backgroundColor: 'black',
});
export const dotLeft1 = ({ theme, offset }) => ({
    ...dotBase(),
    marginLeft: 4,
});
export const dotLeft2 = ({ theme, offset }) => ({
    ...dotBase(),
    marginLeft: 2,
});
export const dotLeft3 = ({ theme, offset }) => ({
    ...dotBase(),
    height: 16,
});
export const dotRight3 = ({ theme, offset }) => ({
    ...dotBase(),
    marginLeft: 6,
    height: 16,
});

const baseSide = {
    position: 'absolute',
    height: 24,
    top: 0,
};

export const left = ({ theme }) => ({
    ...baseSide,
    left: -6,
});
export const right = ({ theme }) => ({
    ...baseSide,
    right: -6,
});
