export const container = () => ({
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    border: 'none',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
});

export const photo = () => ({
    width: '100%',
    flex: 0,
    height: 'auto',
    display: 'block',
    float: 'left',
});
