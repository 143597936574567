import config from '../../config';
import { ICON_TYPE_ABOUT } from '../../constants/icons';

const borderSize = 3;

export const windowTop = ({ theme, isFocused = true }) => ({
    height: 4,
    width: `calc(100% + ${borderSize}px)`,
    position: 'absolute',
    background: theme.colors.black,
    top: -borderSize,
    left: -borderSize,
    ...(!isFocused && {
        width: `calc(100% + ${borderSize + 2}px)`,
    }),
});
export const windowBottom = ({ theme, isFocused = true }) => ({
    height: 4,
    width: `calc(100% + ${borderSize}px)`,
    position: 'absolute',
    background: theme.colors.black,
    bottom: -(borderSize * 2),
    right: -(borderSize * 2),
    ...(!isFocused && {
        width: `calc(100% + ${borderSize + 2}px)`,
    }),
});
export const windowLeft = ({ theme, isFocused = true }) => ({
    width: 4,
    height: `calc(100% + ${borderSize}px)`,
    position: 'absolute',
    background: theme.colors.black,
    top: -borderSize,
    left: -borderSize,
    ...(!isFocused && {
        top: 0,
        height: `calc(100% + ${2 * borderSize}px)`,
    }),
});
export const windowRight = ({ theme, isFocused = true }) => ({
    width: 4,
    height: `calc(100% + ${borderSize}px)`,
    position: 'absolute',
    background: theme.colors.black,
    top: borderSize,
    right: -(borderSize * 2),
    ...(!isFocused && {
        top: -borderSize,
        height: `calc(100% + ${borderSize * 2}px)`,
    }),
});

export const borderWrapper = ({ theme, type }) => ({
    ...(type === ICON_TYPE_ABOUT && {
        '>div': {
            borderColor: theme.colors.darkBlue,
            background: theme.colors.darkBlue,
        },
    }),
});

export const window = ({ theme, zIndex, isFocused, type }) => ({
    marginTop: borderSize,
    marginLeft: borderSize,
    height: `calc(100% - ${borderSize * 2}px)`,
    width: `calc(100% - ${borderSize * 2}px)`,
    background: theme.colors.white,
    borderStyle: 'solid',
    borderColor: theme.colors.black,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: borderSize,
    position: 'relative',
    zIndex,
    borderBottomWidth: 0,
    '&:after': {
        position: 'absolute',
        bottom: -borderSize,
        ...((isFocused && {
            left: 0,
            width: 'calc(100% + 2px)',
        }) || {
            left: -borderSize,
            width: 'calc(100% + 6px)',
        }),
        height: borderSize,
        backgroundColor: theme.colors.black,
        content: '""',
        zIndex: 99,
    },
    ...(type === ICON_TYPE_ABOUT && {
        borderColor: theme.colors.darkBlue,
        background: theme.colors.blue,
    }),
});

export const content = ({ theme }) => ({
    cursor: 'default',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
});

export const wrapperOuter = () => ({
    width: '100%',
    height: 'calc(100% - 8px)',
    overflow: 'hidden',
});

export const wrapper = ({ height, headerHeight, isDragging, hasScrollbar, browserScrollbarWidth = 0 }) => ({
    position: 'relative',
    width: `calc(100% + ${browserScrollbarWidth}px)`,
    ...(hasScrollbar && {
        paddingRight: config.navbar.scrollbarWidth,
    }),
    ...((isDragging && {
        overflow: 'hidden',
        ...(hasScrollbar && {
            paddingRight: config.navbar.scrollbarWidth + browserScrollbarWidth,
        }),
    }) || {
        overflow: 'auto',
    }),
    height: '100%',
    maxHeight: height - headerHeight - borderSize,
    '>div': {
        overflow: 'auto',
    },
});

export const scrollbar = ({ offsetTop }) => ({
    position: 'absolute',
    right: 0,
    top: offsetTop,
    width: config.navbar.scrollbarWidth,
    height: `calc(100% - ${offsetTop}px)`,
    padding: '0',
    margin: '0',
    borderLeft: `${borderSize}px solid black`,
    backgroundImage: 'radial-gradient(#000000 20%, #ffffff 20%)',
    backgroundPosition: '1.1px 0',
    backgroundSize: '6px 6px',
    boxSizing: 'border-box',
});

export const scrollbarContent = ({ theme, height, top }) => ({
    display: height === 0 ? 'none' : 'block',
    height,
    position: 'relative',
    top,
    width: '100%',
    background: theme.colors.white,
    borderTop: top > 0 ? `${borderSize}px solid ${theme.colors.black}` : 0,
    borderBottom: `${borderSize}px solid ${theme.colors.black}`,
    zIndex: 10,
});
const btn = {
    display: 'block',
    margin: 0,
    padding: 0,
    width: '100%',
    height: config.navbar.iconHeight,
    background: 'white',
    zIndex: 11,
    position: 'relative',
    border: 'none',
    borderStyle: 'solid',
    '-webkit-appearance': 'none',
    boxShadow: 'none',
    outlineOffset: '-2px',
    '>img': {
        position: 'absolute',
        margin: 'auto',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: 22,
        paddingBottom: 1,
    },
};
export const buttonTop = ({ theme }) => ({
    ...btn,
    borderBottomWidth: borderSize,
    ':after': {
        content: '" "',
        background: theme.colors.black,
        height: borderSize,
        width: `calc(100% + ${borderSize}px)`,
        position: 'absolute',
        bottom: -borderSize,
        left: -borderSize,
    },
});
export const buttonBottom = ({ theme }) => ({
    ...btn,
    left: 0,
    bottom: 0,
    position: 'absolute',
    borderTop: `${borderSize}px solid black`,
});

export const windowWrapper = ({ theme, zIndex, type }) => ({
    float: 'left',
    zIndex,
    ...(type === ICON_TYPE_ABOUT && {
        borderColor: theme.colors.blue,
        color: theme.colors.white,
    }),
});
