import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { combineRules } from 'fela';
import { connect as connectFela, FelaComponent } from 'react-fela';
import * as stylesAll from './Navbar.style';
import openSourceIcon from '../../images/open_source.svg';
import CurrentTime from './CurrentTime';
import TopMenuItem from './TopMenuItem';
import ClickAlerter from '../ClickAlerter';
import Logo from '../Logo';
import themeConfig from '../../styles/theme';
import { MENU_ITEMS, MENU_ITEMS_KEYS } from '../../data/Navbar.items';
import FullScreen from './FullScreen';
import SearchButton from './Search/SearchButton';
import AppContext from '../../context/AppContext';

const Navbar = ({ styles }) => {
    const [menuItemOpened, setItemOpened] = useState('');
    const [isLogoHovered, setLogoHovered] = useState(false);

    const onOutsideClick = useCallback(() => {
        setLogoHovered(false);
        setItemOpened('');
    }, []);

    return (
        <nav className={styles.navWrapper}>
            <ClickAlerter onOutsideClick={onOutsideClick} deps={[menuItemOpened]}>
                <div className={styles.nav}>
                    <TopMenuItem
                        itemName={MENU_ITEMS_KEYS[0]}
                        items={MENU_ITEMS[MENU_ITEMS_KEYS[0]]}
                        isOpened={MENU_ITEMS_KEYS[0] === menuItemOpened}
                        setItemOpened={itemToOpen => {
                            setItemOpened(itemToOpen);
                            if (itemToOpen !== '' && itemToOpen !== MENU_ITEMS_KEYS[0]) {
                                setLogoHovered(false);
                            }
                        }}
                        key={MENU_ITEMS_KEYS[0]}
                        onMouseEnter={() => setLogoHovered(true)}
                        onMouseLeave={() => setLogoHovered(menuItemOpened === MENU_ITEMS_KEYS[0])}
                    >
                        <Logo fill={isLogoHovered ? themeConfig.colors.white : themeConfig.colors.blue} />
                    </TopMenuItem>
                    {MENU_ITEMS_KEYS.slice(1).map(itemName => (
                        <TopMenuItem
                            itemName={itemName}
                            items={MENU_ITEMS[itemName]}
                            isOpened={itemName === menuItemOpened}
                            setItemOpened={itemToOpen => {
                                setLogoHovered(false);
                                setItemOpened(itemToOpen);
                            }}
                            key={itemName}
                        />
                    ))}
                </div>
            </ClickAlerter>
            <ul className={styles.navRight}>
                <FelaComponent as="li" style={combineRules(stylesAll.noHoverItem, stylesAll.hideOnMobile)}>
                    <FullScreen className={styles.button} />
                </FelaComponent>
                <FelaComponent as="li" style={combineRules(stylesAll.noHoverItem, stylesAll.hideOnMobile)}>
                    <AppContext.Consumer>
                        {({ desktopDispatch }) => (
                            <SearchButton className={styles.button} desktopDispatch={desktopDispatch} />
                        )}
                    </AppContext.Consumer>
                </FelaComponent>
                <FelaComponent as="li" style={combineRules(stylesAll.noHoverItem, stylesAll.hideOnMobile)} padding>
                    <CurrentTime />
                </FelaComponent>
                <FelaComponent as="li" style={combineRules(stylesAll.noHoverItem, stylesAll.hideOnMobile)} padding>
                    {/* Disable because we want to track visitors from Google Analytics */}
                    {/* eslint-disable react/jsx-no-target-blank */}
                    <a href="https://www.ackee.cz" target="_blank">
                        <img src={openSourceIcon} alt="Open source logo - Ackee" />
                    </a>
                </FelaComponent>
            </ul>
        </nav>
    );
};

Navbar.propTypes = {
    styles: PropTypes.shape({
        navRight: PropTypes.string,
        noHoverItem: PropTypes.string,
        button: PropTypes.string,
        nav: PropTypes.string,
        navWrapper: PropTypes.string,
        logo: PropTypes.string,
    }).isRequired,
};

export default connectFela(stylesAll)(Navbar);
