import iconOpenSource from '../../images/icons/aos_nfo.svg';
import iconBackend from '../../images/icons/backend.svg';
import iconIOS from '../../images/icons/ios.svg';
import iconAndroid from '../../images/icons/android.svg';
import iconWeb from '../../images/icons/web.svg';
import iconBin from '../../images/icons/bin.svg';
import * as constants from '../../constants/icons';

export default [
    {
        dataId: 'openSource',
        titleKey: 'desktop.icons.openSource',
        icon: iconOpenSource,
        initPosition: {
            x: 150,
            y: 150,
        },
        type: constants.ICON_TYPE_EDITOR,
        isDeleted: false,
    },
    {
        dataId: 'backend',
        titleKey: 'desktop.icons.backend',
        icon: iconBackend,
        initPosition: {
            x: 1800,
            y: 150,
        },
        type: constants.ICON_TYPE_PROJECTS,
        isDeleted: false,
    },
    {
        dataId: 'ios',
        titleKey: 'desktop.icons.ios',
        icon: iconIOS,
        initPosition: {
            x: 1950,
            y: 150,
        },
        type: constants.ICON_TYPE_PROJECTS,
        isDeleted: false,
    },
    {
        dataId: 'android',
        titleKey: 'desktop.icons.android',
        icon: iconAndroid,
        initPosition: {
            x: 2100,
            y: 150,
        },
        type: constants.ICON_TYPE_PROJECTS,
        isDeleted: false,
    },
    {
        dataId: 'web',
        titleKey: 'desktop.icons.web',
        icon: iconWeb,
        initPosition: {
            x: 2250,
            y: 150,
        },
        type: constants.ICON_TYPE_PROJECTS,
        isDeleted: false,
    },
    {
        dataId: 'bin',
        titleKey: 'desktop.icons.bin',
        icon: iconBin,
        initPosition: {
            x: 2250,
            y: 750,
        },
        type: constants.ICON_TYPE_BIN,
        isDeleted: false,
    },
];
