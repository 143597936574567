export const wrapper = ({theme}) => ({
    width: '100%',
    border: `2px solid ${theme.colors.black}`,
    padding: '0 10px',
    height: 'auto',
    offset: 'none',
    outline: 'none',
    fontSize: 16,
    ':focus': {
        borderColor: theme.colors.pink
    }
});
