import bg from '../../images/bg_texture.png';
import config from '../../config';

export const container = () => ({
    height: `calc(100vh - ${config.navbar.height}px)`,
    width: '100%',
    padding: 0,
    margin: 0,
    position: 'relative',
    backgroundImage: `url(${bg})`,
    overflow: 'hidden',
    maxWidth480: {
        height: `calc(100vh - ${config.navbar.heightMobile}px)`,
    },
});

export const openButton = ({ theme }) => ({
    background: theme.colors.white,
    color: theme.colors.black,
    padding: '8px 12px 8px 12px',
    lineHeight: '23px',
    fontSize: 16,
    border: '3px solid black',
    marginRight: 8,
    cursor: 'pointer',
    marginTop: 8,
});

const crossBase = {
    position: 'absolute',
    content: '""',
    height: 14,
    width: 3,
    backgroundColor: '#000',
    top: 0,
    bottom: 0,
    margin: 'auto',
};

export const cross = ({ theme }) => ({
    width: 14,
    height: 14,
    position: 'relative',
    padding: '0 16px 0 24px',
    ':after': {
        ...crossBase,
    },
    ':before': {
        ...crossBase,
        transform: 'rotate(90deg)',
    },
});

export const minimizedWindowsWrapper = () => ({
    position: 'fixed',
    overflow: 'auto',
    bottom: 0,
    left: 0,
    padding: '0 30px',
    width: '100%',
    zIndex: 10,
});

export const openButtonText = () => ({
    width: 190,
    textAlign: 'left',
    display: 'block',
    float: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 600,
});
