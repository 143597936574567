import React from 'react';
import PropTypes from 'prop-types';
import { connect as connectFela, FelaComponent } from 'react-fela';
import * as stylesAll from './ProgressBar.style';

const ProgressBar = ({ styles }) => (
    <div className={styles.wrapper}>
        <span className={styles.progress} />
    </div>
);

ProgressBar.propTypes = {
    styles: PropTypes.shape({
        wrapper: PropTypes.string,
        logo: PropTypes.string,
        innerWrapper: PropTypes.string,
        progress: PropTypes.string,
    }).isRequired,
};

export default connectFela(stylesAll)(ProgressBar);
