import React from 'react';
import PropTypes from 'prop-types';

const BehindTheGridLogo = ({ fill, ...props }) => (
    <svg width="466px" height="99px" viewBox="0 0 426 99" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="system_loading-screen_text" transform="translate(-626.000000, -220.000000)" fill={fill}>
                <g id="Group-84" transform="translate(626.000000, 220.000000)">
                    <polygon
                        id="Fill-1"
                        points="3.79954545 45.2454659 16.1392045 45.2454659 17.0883523 41.132 4.74943182 41.132"
                    />
                    <polygon
                        id="Fill-2"
                        points="20.8870114 24.6790227 8.54809091 24.6790227 7.59820455 28.79175 19.9378636 28.79175"
                    />
                    <polygon
                        id="Fill-3"
                        points="22.7870057 16.4530511 10.446608 16.4530511 9.49746023 20.5657784 21.8371193 20.5657784"
                    />
                    <polygon
                        id="Fill-4"
                        points="24.6855227 8.22634091 12.3466023 8.22634091 11.3967159 12.3390682 23.736375 12.3390682"
                    />
                    <polygon
                        id="Fill-5"
                        points="31.3274886 32.905733 18.9885682 32.905733 6.64817045 32.905733 5.69902273 37.0184602 18.0386818 37.0184602 30.3783409 37.0184602 42.718 37.0184602 55.0576591 37.0184602 56.0068068 32.905733 43.6671477 32.905733"
                    />
                    <polygon
                        id="Fill-6"
                        points="38.9217784 0.00450568182 26.582858 0.00450568182 25.632233 4.11723295 25.6344489 4.11723295 24.6853011 8.22626705 37.0249602 8.22626705 49.3646193 8.22626705 50.313767 4.11353977 50.3122898 4.11353977 51.2614375 0.00450568182"
                    />
                    <polygon
                        id="Fill-7"
                        points="40.8186705 45.2454659 53.1575909 45.2454659 54.1074773 41.132 41.7678182 41.132"
                    />
                    <polygon
                        id="Fill-8"
                        points="1.899625 53.4714375 14.2392841 53.4714375 15.1884318 49.3587102 2.84877273 49.3587102"
                    />
                    <polygon
                        id="Fill-9"
                        points="38.9180114 53.4714375 51.2576705 53.4714375 52.2068182 49.3587102 39.8671591 49.3587102"
                    />
                    <polygon
                        id="Fill-10"
                        points="0.949295455 57.5844602 0.000886363636 61.6979261 12.3398068 61.6979261 13.2896932 57.5844602"
                    />
                    <polygon
                        id="Fill-11"
                        points="37.0194943 61.6981477 49.3591534 61.6981477 50.3083011 57.5846818 37.9693807 57.5846818"
                    />
                    <polygon
                        id="Fill-12"
                        points="44.6171818 28.7920455 56.9561023 28.7920455 57.9059886 24.6793182 45.5663295 24.6793182"
                    />
                    <polygon
                        id="Fill-13"
                        points="47.4656591 16.4530511 46.5157727 20.5657784 58.8561705 20.5657784 59.8053182 16.4530511"
                    />
                    <polygon
                        id="Fill-14"
                        points="60.7546136 12.3393636 61.7045 8.22663636 49.3648409 8.22663636 48.4156932 12.3393636"
                    />
                    <polygon
                        id="Fill-15"
                        points="326.050636 45.2454659 338.390295 45.2454659 339.339443 41.132 326.999784 41.132"
                    />
                    <polygon
                        id="Fill-16"
                        points="329.849074 28.7920455 342.188733 28.7920455 343.137881 24.6793182 330.798222 24.6793182"
                    />
                    <polygon
                        id="Fill-17"
                        points="327.924631 37.0187557 340.263551 37.0187557 341.213437 32.9060284 328.873778 32.9060284"
                    />
                    <polygon
                        id="Fill-18"
                        points="336.489341 -0.000147727273 335.540193 4.11331818 335.518773 4.11331818 334.568886 8.22604545 334.596955 8.22604545 333.647068 12.3395114 333.644852 12.3395114 332.695705 16.4529773 332.69792 16.4529773 331.748034 20.5657045 344.088432 20.5657045 345.036841 16.4529773 345.034625 16.4529773 345.984511 12.3395114 345.985989 12.3395114 346.935875 8.22604545 346.908545 8.22604545 347.857693 4.11331818 347.879852 4.11331818 348.829739 -0.000147727273"
                    />
                    <polygon
                        id="Fill-19"
                        points="321.279784 12.3393636 321.27683 12.3393636 320.327682 16.4528295 320.330636 16.4528295 319.38075 20.5662955 331.720409 20.5662955 332.669557 16.4528295 332.666602 16.4528295 333.616489 12.3393636 333.618705 12.3393636 334.568591 8.22663636 322.228932 8.22663636"
                    />
                    <polygon
                        id="Fill-20"
                        points="324.150642 53.4714375 336.489562 53.4714375 337.439449 49.3587102 325.09979 49.3587102"
                    />
                    <polygon
                        id="Fill-21"
                        points="322.250648 61.6981477 334.590307 61.6981477 335.540193 57.5846818 323.200534 57.5846818"
                    />
                    <polygon
                        id="Fill-22"
                        points="383.486114 41.1317045 371.146455 41.1317045 370.197307 45.2451705 382.536227 45.2451705"
                    />
                    <polygon
                        id="Fill-23"
                        points="387.284551 24.6790227 374.944892 24.6790227 373.995744 28.79175 386.334665 28.79175"
                    />
                    <polygon
                        id="Fill-24"
                        points="385.383892 32.905733 373.044233 32.905733 372.094347 37.0184602 384.434744 37.0184602"
                    />
                    <polygon
                        id="Fill-25"
                        points="409.060659 37.0187557 421.400318 37.0187557 422.350205 32.9060284 410.009807 32.9060284"
                    />
                    <polygon
                        id="Fill-26"
                        points="389.183807 16.4530511 376.844148 16.4530511 375.894261 20.5657784 388.23392 20.5657784"
                    />
                    <polygon
                        id="Fill-27"
                        points="377.794108 12.3393636 390.133028 12.3393636 391.082915 8.22663636 378.743256 8.22663636"
                    />
                    <polygon
                        id="Fill-28"
                        points="405.319244 0.00450568182 392.979585 0.00450568182 392.030437 4.11723295 392.031915 4.11723295 391.083506 8.22626705 403.422426 8.22626705 415.761347 8.22626705 416.711233 4.11353977 416.710494 4.11353977 417.658903 0.00450568182"
                    />
                    <polygon
                        id="Fill-29"
                        points="392.97508 53.4735795 380.636159 53.4735795 379.686273 57.5870455 379.68775 57.5870455 378.739341 61.6953409 391.079 61.6953409 403.418659 61.6953409 404.367807 57.581875 404.36633 57.581875 405.314739 53.4735795"
                    />
                    <polygon
                        id="Fill-30"
                        points="407.215472 45.2454659 419.555131 45.2454659 420.505017 41.132 408.164619 41.132"
                    />
                    <polygon
                        id="Fill-31"
                        points="381.585381 49.3584148 369.24646 49.3584148 368.296574 53.471142 380.636233 53.471142"
                    />
                    <polygon
                        id="Fill-32"
                        points="405.314813 53.4714375 417.654472 53.4714375 418.603619 49.3587102 406.264699 49.3587102"
                    />
                    <polygon
                        id="Fill-33"
                        points="346.931517 53.4714375 345.982369 57.5841648 345.98754 57.5841648 345.037653 61.6983693 357.377313 61.6983693 358.327199 57.5841648 358.322028 57.5841648 359.271176 53.4714375"
                    />
                    <polygon
                        id="Fill-34"
                        points="411.013983 28.7920455 423.353642 28.7920455 424.303528 24.6793182 411.963131 24.6793182"
                    />
                    <polygon
                        id="Fill-35"
                        points="412.913239 20.5660739 425.252898 20.5660739 426.202045 16.4533466 413.863125 16.4533466"
                    />
                    <polygon
                        id="Fill-36"
                        points="415.761494 8.22641477 414.812347 12.339142 427.152744 12.339142 428.101892 8.22641477"
                    />
                    <polygon
                        id="Fill-37"
                        points="66.4523807 32.905733 65.503233 37.0184602 77.842892 37.0184602 78.7920398 32.905733"
                    />
                    <polygon
                        id="Fill-38"
                        points="80.6913693 24.6790227 68.3517102 24.6790227 67.4025625 28.79175 79.741483 28.79175"
                    />
                    <polygon
                        id="Fill-39"
                        points="82.5905511 16.4530511 70.250892 16.4530511 69.3010057 20.5657784 81.6414034 20.5657784"
                    />
                    <polygon
                        id="Fill-40"
                        points="71.2008523 12.3393636 83.5397727 12.3393636 84.4896591 8.22663636 72.15 8.22663636"
                    />
                    <polygon
                        id="Fill-41"
                        points="88.2832955 45.2454659 100.622216 45.2454659 112.961875 45.2454659 113.911761 41.132 114.860909 37.0185341 102.52125 37.0185341 90.1815909 37.0185341 77.8426705 37.0185341 76.8927841 41.132 64.553125 41.132 63.6039773 45.2454659 75.9428977 45.2454659"
                    />
                    <polygon
                        id="Fill-42"
                        points="74.0496989 -0.000147727273 73.0990739 4.11331818 85.4402102 4.11331818 84.4895852 8.22604545 96.8292443 8.22604545 109.168165 8.22604545 121.509301 8.22604545 122.458449 4.11331818 123.407597 -0.000147727273"
                    />
                    <polygon
                        id="Fill-43"
                        points="123.407449 45.2454659 135.747108 45.2454659 136.696994 41.132 124.357335 41.132"
                    />
                    <polygon
                        id="Fill-44"
                        points="125.306705 37.0187557 137.646364 37.0187557 138.595511 32.9060284 126.256591 32.9060284"
                    />
                    <polygon
                        id="Fill-45"
                        points="127.20596 28.7920455 139.545619 28.7920455 140.495506 24.6793182 128.155847 24.6793182"
                    />
                    <polygon
                        id="Fill-46"
                        points="129.105216 20.5660739 141.444875 20.5660739 142.394023 16.4533466 130.055102 16.4533466"
                    />
                    <polygon
                        id="Fill-47"
                        points="131.953693 8.22634091 131.004545 12.3390682 143.344205 12.3390682 144.294091 8.22634091"
                    />
                    <polygon
                        id="Fill-48"
                        points="133.853983 -0.000147727273 132.903358 4.11331818 145.243756 4.11331818 146.192903 -0.000147727273"
                    />
                    <polygon
                        id="Fill-49"
                        points="150.936057 32.905733 149.98617 37.0184602 162.32583 37.0184602 163.274977 32.905733 164.224864 28.792267 151.885205 28.792267 139.545545 28.792267 138.595659 32.905733"
                    />
                    <polygon
                        id="Fill-50"
                        points="152.834648 24.6790227 153.784534 20.5662955 141.444875 20.5662955 140.495727 24.6790227"
                    />
                    <polygon
                        id="Fill-51"
                        points="142.39417 16.4530511 154.734568 16.4530511 155.683716 12.3395852 143.344057 12.3395852"
                    />
                    <polygon
                        id="Fill-52"
                        points="160.426574 45.2454659 172.766233 45.2454659 173.715381 41.132 174.665267 37.0185341 162.325608 37.0185341 161.37646 41.132"
                    />
                    <polygon
                        id="Fill-53"
                        points="170.871852 -0.000147727273 169.922705 4.11331818 157.583045 4.11331818 156.633159 8.22604545 155.684011 12.3395114 168.022932 12.3395114 168.972818 8.22604545 181.312477 8.22604545 182.262364 4.11331818 183.21225 -0.000147727273"
                    />
                    <polygon
                        id="Fill-54"
                        points="186.060284 32.905733 185.111136 37.0184602 197.450795 37.0184602 198.399943 32.905733"
                    />
                    <polygon
                        id="Fill-55"
                        points="189.858795 16.4530511 188.909648 20.5657784 201.248568 20.5657784 202.198455 16.4530511"
                    />
                    <polygon
                        id="Fill-56"
                        points="204.09771 8.22634091 191.758051 8.22634091 190.808903 12.3390682 203.148562 12.3390682"
                    />
                    <polygon
                        id="Fill-57"
                        points="209.790381 37.0187557 197.450722 37.0187557 196.500835 41.131483 184.161915 41.131483 183.212028 45.2456875 195.551687 45.2456875 207.890608 45.2456875 220.231006 45.2456875 232.569926 45.2456875 233.519812 41.131483 234.46896 37.0187557 222.129301 37.0187557"
                    />
                    <polygon
                        id="Fill-58"
                        points="211.688898 28.7920455 224.029295 28.7920455 236.368216 28.7920455 237.318102 24.6793182 238.26725 20.5658523 225.927591 20.5658523 213.58867 20.5658523 201.248273 20.5658523 200.299125 24.6793182 187.959466 24.6793182 187.010318 28.7920455 199.349977 28.7920455"
                    />
                    <polygon
                        id="Fill-59"
                        points="193.656937 -0.000147727273 192.708528 4.11331818 205.047449 4.11331818 204.097562 8.22604545 216.437222 8.22604545 228.776881 8.22604545 241.11654 8.22604545 242.066426 4.11331818 243.016312 -0.000147727273"
                    />
                    <polygon
                        id="Fill-60"
                        points="245.864568 32.905733 244.914682 37.0184602 257.254341 37.0184602 258.204227 32.905733"
                    />
                    <polygon
                        id="Fill-61"
                        points="249.66308 16.4530511 248.713193 20.5657784 261.052852 20.5657784 262.002739 16.4530511"
                    />
                    <polygon
                        id="Fill-62"
                        points="250.612301 12.3393636 262.95196 12.3393636 263.901847 8.22663636 251.562187 8.22663636"
                    />
                    <polygon
                        id="Fill-63"
                        points="269.594 37.0187557 257.254341 37.0187557 256.305193 41.131483 243.965534 41.131483 243.016386 45.2456875 255.355307 45.2456875 267.694966 45.2456875 280.034625 45.2456875 292.374284 45.2456875 293.32417 41.131483 294.273318 37.0187557 281.933659 37.0187557"
                    />
                    <polygon
                        id="Fill-64"
                        points="273.392437 20.5660739 261.052778 20.5660739 260.103631 24.6788011 247.763972 24.6788011 246.814824 28.792267 259.153744 28.792267 271.493403 28.792267 283.833062 28.792267 296.172722 28.792267 297.121869 24.6788011 298.071756 20.5660739 285.732097 20.5660739"
                    />
                    <polygon
                        id="Fill-65"
                        points="253.461222 -0.000147727273 252.511335 4.11331818 264.851733 4.11331818 263.901847 8.22604545 276.241506 8.22604545 288.580426 8.22604545 300.920824 8.22604545 301.87071 4.11331818 302.819858 -0.000147727273"
                    />
                    <polygon
                        id="Fill-66"
                        points="59.7801307 61.7169091 120.592062 61.7169091 122.084847 55.2538409 61.3829716 55.2538409"
                    />
                    <polygon
                        id="Fill-67"
                        points="234.689074 61.7169091 280.95504 61.7169091 282.449301 55.2538409 236.188506 55.2538409"
                    />
                    <polygon
                        id="Fill-68"
                        points="284.768472 61.7169091 288.795517 61.7169091 290.29421 55.2538409 286.267165 55.2538409"
                    />
                    <path
                        d="M130.532557,57.8203068 L133.117784,57.8203068 L133.117784,56.5276932 L130.532557,56.5276932 L130.532557,57.8203068 Z M130.532557,60.4055341 L133.117784,60.4055341 L133.117784,59.1129205 L130.532557,59.1129205 L130.532557,60.4055341 Z M134.410398,55.2350795 L127.94733,55.2350795 L126.515852,61.6981477 L134.410398,61.6981477 L134.410398,60.4055341 L135.703011,60.4055341 L135.703011,59.1129205 L134.410398,59.1129205 L134.410398,57.8203068 L135.703011,57.8203068 L135.703011,56.5276932 L134.410398,56.5276932 L134.410398,55.2350795 Z"
                        id="Fill-69"
                    />
                    <path
                        d="M139.580852,59.1129205 L140.873466,59.1129205 L140.873466,57.8203068 L139.580852,57.8203068 L139.580852,59.1129205 Z M138.288239,57.8203068 L136.995625,57.8203068 L136.995625,60.4055341 L138.288239,60.4055341 L138.288239,61.6981477 L143.458693,61.6981477 L143.458693,60.4055341 L142.16608,60.4055341 L142.16608,59.1129205 L143.458693,59.1129205 L143.458693,56.5276932 L138.288239,56.5276932 L138.288239,57.8203068 Z"
                        id="Fill-70"
                    />
                    <polygon
                        id="Fill-71"
                        points="149.921909 56.5276932 147.336682 56.5276932 147.336682 55.2350795 144.751455 55.2350795 144.751455 61.6981477 147.336682 61.6981477 147.336682 57.8203068 148.629295 57.8203068 148.629295 61.6981477 151.214523 61.6981477 151.214523 57.8203068 149.921909 57.8203068"
                    />
                    <polygon
                        id="Fill-72"
                        points="152.506989 61.6981477 155.092216 61.6981477 155.092216 57.8203068 152.506989 57.8203068"
                    />
                    <polygon
                        id="Fill-73"
                        points="152.506989 56.5276932 155.092216 56.5276932 155.092216 55.2350795 152.506989 55.2350795"
                    />
                    <polygon
                        id="Fill-74"
                        points="161.555432 56.5276932 156.384977 56.5276932 156.384977 61.6981477 158.970205 61.6981477 158.970205 57.8203068 160.262818 57.8203068 160.262818 61.6981477 162.848045 61.6981477 162.848045 57.8203068 161.555432 57.8203068"
                    />
                    <path
                        d="M166.725739,60.4055341 L168.018352,60.4055341 L168.018352,57.8203068 L166.725739,57.8203068 L166.725739,60.4055341 Z M168.018352,56.5276932 L165.433125,56.5276932 L165.433125,57.8203068 L164.140511,57.8203068 L164.140511,60.4055341 L165.433125,60.4055341 L165.433125,61.6981477 L170.60358,61.6981477 L170.60358,55.2350795 L168.018352,55.2350795 L168.018352,56.5276932 Z"
                        id="Fill-75"
                    />
                    <polygon
                        id="Fill-76"
                        points="180.944636 55.2350795 178.359409 55.2350795 178.359409 56.5276932 177.066795 56.5276932 177.066795 57.8203068 178.359409 57.8203068 178.359409 61.6981477 180.944636 61.6981477 180.944636 57.8203068 182.23725 57.8203068 182.23725 56.5276932 180.944636 56.5276932"
                    />
                    <polygon
                        id="Fill-77"
                        points="188.700318 56.5276932 186.115091 56.5276932 186.115091 55.2350795 183.529864 55.2350795 183.529864 61.6981477 186.115091 61.6981477 186.115091 57.8203068 187.407705 57.8203068 187.407705 61.6981477 189.992932 61.6981477 189.992932 57.8203068 188.700318 57.8203068"
                    />
                    <path
                        d="M193.870625,59.1129205 L195.163239,59.1129205 L195.163239,57.8203068 L193.870625,57.8203068 L193.870625,59.1129205 Z M192.578011,57.8203068 L191.285398,57.8203068 L191.285398,60.4055341 L192.578011,60.4055341 L192.578011,61.6981477 L197.748466,61.6981477 L197.748466,60.4055341 L196.455852,60.4055341 L196.455852,59.1129205 L197.748466,59.1129205 L197.748466,56.5276932 L192.578011,56.5276932 L192.578011,57.8203068 Z"
                        id="Fill-78"
                    />
                    <path
                        d="M205.504517,56.5274716 L205.504517,57.8208239 L204.211165,57.8208239 L204.211165,60.4060511 L205.504517,60.4060511 L205.504517,61.6979261 L208.089744,61.6979261 L208.089744,62.9912784 L205.504517,62.9912784 L205.504517,64.2831534 L209.382358,64.2831534 L209.382358,62.9912784 L210.674233,62.9912784 L210.674233,57.8208239 L209.382358,57.8208239 L209.382358,56.5274716 L205.504517,56.5274716 Z M206.797131,60.4060511 L208.089744,60.4060511 L208.089744,57.8208239 L206.797131,57.8208239 L206.797131,60.4060511 Z"
                        id="Fill-79"
                    />
                    <polygon
                        id="Fill-80"
                        points="215.845205 57.7994034 215.824523 57.8200852 214.552591 57.8200852 214.552591 56.5274716 211.967364 56.5274716 211.967364 61.6979261 214.552591 61.6979261 214.552591 59.1126989 215.845205 59.1126989 215.845205 57.8415057 215.865886 57.8200852 217.137818 57.8200852 217.137818 56.5274716 215.845205 56.5274716"
                    />
                    <polygon
                        id="Fill-81"
                        points="218.430284 61.6981477 221.015511 61.6981477 221.015511 57.8203068 218.430284 57.8203068"
                    />
                    <polygon
                        id="Fill-82"
                        points="218.430284 56.5276932 221.015511 56.5276932 221.015511 55.2350795 218.430284 55.2350795"
                    />
                    <path
                        d="M224.893352,60.4055341 L226.185966,60.4055341 L226.185966,57.8203068 L224.893352,57.8203068 L224.893352,60.4055341 Z M226.185966,56.5276932 L223.600739,56.5276932 L223.600739,57.8203068 L222.308125,57.8203068 L222.308125,60.4055341 L223.600739,60.4055341 L223.600739,61.6981477 L228.771193,61.6981477 L230.263977,55.2350795 L226.185966,55.2350795 L226.185966,56.5276932 Z"
                        id="Fill-83"
                    />
                </g>
            </g>
        </g>
    </svg>
);

BehindTheGridLogo.propTypes = {
    fill: PropTypes.string.isRequired,
};

export default BehindTheGridLogo;
