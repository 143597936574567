import {DIALOG_INFO, DIALOG_SHARE, DIALOG_WARNING} from '../../constants/DialogTypes';

const wrapper = {
    overflow: 'visible',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: 300,
    height: 250,
    zIndex: 1000,
    margin: 'auto',
    padding: '35px 20px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    flexDirection: 'row',
};
export const back = ({ theme }) => ({
    opacity: 1,
    background: 'transparent',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
});

export const message = ({ theme, type }) => ({
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: '0.7',
    lineHeight: 1.375,
    paddingRight: 25,
    ...(type === DIALOG_SHARE && {
        justifyContent: 'flex-start'
    })
});

export const windowTop = ({ theme }) => ({
    height: 3,
    width: 'calc(100% + 3px)',
    position: 'absolute',
    background: theme.colors.redDark,
    top: -3,
    left: -3,
});
export const windowBottom = ({ theme }) => ({
    height: 3,
    width: 'calc(100% + 3px)',
    position: 'absolute',
    background: theme.colors.redDark,
    bottom: -6,
    right: -6,
});
export const windowLeft = ({ theme }) => ({
    width: 3,
    height: 'calc(100%)',
    position: 'absolute',
    background: theme.colors.redDark,
    top: 0,
    left: -3,
});
export const buttonsWrapper = ({ theme, dialogType }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    ...(dialogType === DIALOG_INFO && {
        '>button': {
            borderColor: theme.colors.black,
            color: theme.colors.black,
            '>div': {
                '>div': {
                    background: theme.colors.black,
                },
            },
        },
    }),
    ...(dialogType === DIALOG_WARNING && {
        '>button': {
            borderColor: theme.colors.white,
            color: theme.colors.white,
            '>div': {
                '>div': {
                    background: theme.colors.white,
                },
            },
        },
    }),
});
export const windowRight = ({ theme }) => ({
    width: 3,
    height: 'calc(100% + 3px)',
    position: 'absolute',
    background: theme.colors.redDark,
    bottom: -6,
    right: -6,
});

export const windowWrapper = ({ theme }) => ({
    ...wrapper,
});

export const window = ({ theme, type }) => ({
    position: 'relative',
    padding: 15,
    color: theme.colors.white,
    ...(type === DIALOG_INFO && {
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
        borderColor: theme.colors.black,
        '>div': {
            borderColor: theme.colors.black,
            background: theme.colors.black,
        },
    }),
    ...(type === DIALOG_WARNING && {
        backgroundColor: theme.colors.red,
        borderColor: theme.colors.redDark,
        color: theme.colors.white,
    }),
    ...(type === DIALOG_SHARE && {
        backgroundColor: theme.colors.white,
        borderColor: theme.colors.blue,
        color: theme.colors.black,
        '>div': {
            borderColor: theme.colors.blue,
            background: theme.colors.blue,
        },
    }),
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 3,
    borderRightWidth: 3,
});

export const timerWrapper = ({ theme }) => ({
    display: 'flex',
    width: '100%',
    marginTop: 25,
    marginBottom: 25,
    justifyContent: 'center',
    backgroundColor: 'transparent',
});

export const timerText = ({ theme }) => ({
    textAlign: 'center',
    fontWeight: 'normal',
});

export const buttonYes = ({ theme }) => ({
    marginLeft: 15,
});
