import React from 'react';
import { PropTypes } from 'prop-types';
import { FelaComponent } from 'react-fela';
import * as componentStyles from './Search.styles';
import ProjectThumbnail from "../Projects/ProjectThumbnail";

const Search = ({ data }) => (
    <FelaComponent style={componentStyles.container}>
        {data.map(project => (
            <ProjectThumbnail {...{ ...project, key: project.iconName }} />
        ))}
    </FelaComponent>
);

Search.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
};

Search.defaultProps = {
    data: [],
};

export default Search;
