import React, { useCallback, useEffect } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { FelaComponent } from 'react-fela';
import * as stylesAll from './Dialog.style';
import PixelButton from '../Button/PixelButton';
import TimeCounter from '../TimeCounter';
import { DIALOG_INFO, DIALOG_SHARE, DIALOG_WARNING } from '../../constants/DialogTypes';
import FacebookShare from '../Button/FacebookShare';
import TwitterShare from '../Button/TwitterShare';

const Dialog = ({ isActive, messageId, overrideStyles, onClose, onSuccess, onCancel, dialogTimer, type }) => {
    const handleDialogEvent = useCallback(
        callback => {
            callback();
            onClose();
        },
        [onClose],
    );
    // Close dialog on escape key
    const handleKeyDown = useCallback(
        e => {
            // escape key
            if (e.keyCode === 27) handleDialogEvent(onCancel);
        },
        [handleDialogEvent, onCancel],
    );
    useEffect(() => {
        if (isActive) {
            document.addEventListener('keydown', handleKeyDown);
            return () => document.removeEventListener('keydown', handleKeyDown);
        }
        return noop;
    }, [handleKeyDown, isActive]);

    if (!isActive) {
        return false;
    }
    return (
        <>
            <FelaComponent style={stylesAll.back} />
            <FelaComponent style={stylesAll.windowWrapper}>
                <FelaComponent style={[stylesAll.window, overrideStyles]} type={type}>
                    <FelaComponent style={stylesAll.windowTop} />
                    <FelaComponent style={stylesAll.windowBottom} />
                    <FelaComponent style={stylesAll.windowRight} />
                    <FelaComponent style={stylesAll.windowLeft} />
                    <FormattedMessage id={messageId} as="section">
                        {txt => (
                            <FelaComponent style={[stylesAll.buttonsWrapper, stylesAll.message]} type={type} as="p">
                                {txt}
                            </FelaComponent>
                        )}
                    </FormattedMessage>
                    {type === DIALOG_SHARE && (
                        <>
                            <FacebookShare />
                            <TwitterShare />
                        </>
                    )}
                    {dialogTimer && (
                        <FelaComponent style={stylesAll.timerWrapper} as="section">
                            <FelaComponent as="span" style={stylesAll.timerText}>
                                <TimeCounter time={dialogTimer} onFinish={() => handleDialogEvent(onSuccess)} />
                            </FelaComponent>
                        </FelaComponent>
                    )}
                    <FelaComponent style={stylesAll.buttonsWrapper} dialogType={type} as="section">
                        <FormattedMessage id="dialog.no">
                            {txt => <PixelButton onClick={() => handleDialogEvent(onCancel)}>{txt}</PixelButton>}
                        </FormattedMessage>
                        {type !== DIALOG_SHARE && (
                            <FormattedMessage id="dialog.yes">
                                {txt => (
                                    <PixelButton
                                        onClick={() => handleDialogEvent(onSuccess)}
                                        overrideStyles={stylesAll.buttonYes}
                                    >
                                        {txt}
                                    </PixelButton>
                                )}
                            </FormattedMessage>
                        )}
                    </FelaComponent>
                </FelaComponent>
            </FelaComponent>
        </>
    );
};

Dialog.propTypes = {
    isActive: PropTypes.bool.isRequired,
    messageId: PropTypes.string.isRequired,
    overrideStyles: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
    dialogTimer: PropTypes.number,
    type: PropTypes.oneOf([DIALOG_WARNING, DIALOG_INFO, DIALOG_SHARE]),
};

Dialog.defaultProps = {
    overrideStyles: () => {},
    onClose: () => {},
    onCancel: () => {},
    onSuccess: () => {},
    dialogTimer: undefined,
    type: DIALOG_INFO,
};

export default Dialog;
