import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FelaComponent } from 'react-fela';
import * as stylesAll from './DesktopSubMenuRightClick.style';
import ClickAlerter from '../ClickAlerter';
import DesktopSubMenu from './DesktopSubMenu';
import calculateSubmenuPosition from '../../utils/calculateSubmenuPosition';

const DesktopSubMenuRightClick = ({ items, selectedId, onChange, children }) => {
    const [position, setPosition] = useState(false);
    return (
        <FelaComponent style={stylesAll.wrapper} isActive={Boolean(position)}>
            {({ className }) => (
                <ClickAlerter
                    onOutsideClick={e => {
                        setPosition(false);
                    }}
                    onWrapperClick={e => {
                        setPosition(false);
                    }}
                    deps={[Boolean(position)]}
                    className={className}
                    onContextMenu={e => {
                        e.preventDefault();
                        setPosition(calculateSubmenuPosition(e.clientX, e.clientY));
                    }}
                >
                    <>
                        {position && (
                            <DesktopSubMenu
                                position={position}
                                items={items}
                                selectedId={selectedId}
                                onChange={(e, item) => {
                                    onChange(e, item);
                                    setPosition(false);
                                }}
                            />
                        )}
                        {children}
                    </>
                </ClickAlerter>
            )}
        </FelaComponent>
    );
};

DesktopSubMenuRightClick.propTypes = {
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
    selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node,
};

DesktopSubMenuRightClick.defaultProps = {
    children: null,
    selectedId: -1,
};

export default DesktopSubMenuRightClick;
