import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-fela';

import * as componentStyles from './ProjectThumbnail.styles';

const ProjectThumbnail = ({ styles, title, description, repoUrl, iconName }) => (
    <a href={repoUrl} target="_blank" rel="noreferrer noopener nofollow" className={styles.container}>
        <img src={iconName} alt={title} />
        <h2 className={styles.title}>{title}</h2>
        <p>{description}</p>
    </a>
);

ProjectThumbnail.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    repoUrl: PropTypes.string,
    iconName: PropTypes.string.isRequired,
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
};

ProjectThumbnail.defaultProps = {
    title: '',
    description: '',
    repoUrl: '',
};

export default connect(componentStyles)(ProjectThumbnail);
