import config from "../../../config";

export const container = ({theme}) => ({
    maxWidth: 620,
    margin: '40px auto',
    color: 'white',
    position: 'relative',
    width: '100%',
    float: 'none',
    padding: '12px 24px',
    clear: 'both',
    '>img': {
        display: 'block',
        margin: '0 auto',
        width: '100%',
        maxWidth: 607,
    },
    '>h1': {
        textAlign: 'left',
        display: 'block',
        color: theme.colors.white,
        fontFamily: theme.font.pixel,
        fontWeight: 'bold',
        letterSpacing: '0.7px',
        lineHeight: '18px',
        fontSize: 16,
        marginTop: 0,
        marginLeft: 100,
    },
    paddingBottom: 60,
});

export const systemInfo = ({ theme }) => ({
    width: '100%',
    display: 'block',
    clear: 'both',
    float: 'left',
    color: theme.colors.white,
    '>ul': {
        padding: '25px 0 5px',
        fontWeight: 'bold',
        listStyle: 'none',
        margin: '0 auto',
        width: 340,
        maxWidth: '100%',
        display: 'block',
        '>li': {
            lineHeight: 1.65,
            marginBottom: '0'
        }
    },
});

export const bottomWrapper = ({theme}) => ({
    position: 'fixed',
    left: 0,
    paddingRight: 30,
    bottom: 4,
    height: 60,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '>button': {
    }
});

const headerPseudo = ({
    width: 19,
    height: 6,
    background: 'white',
    content: '" "',
    position: 'absolute',
});

const headerBlock = {
    position: 'absolute',
    top: -8,
    width: 96,
    height: 6,
    background: 'white',
};
export const rightHeaderBlock = ({theme}) => ({
    ...headerBlock,
    right: 0,
    ':before': {
        ...headerPseudo,
        bottom: -6,
        right: 3,
    }
});

export const leftHeaderBlock = ({theme}) => ({
    ...headerBlock,
    left: 0,
    ':before': {
        ...headerPseudo,
        bottom: -6,
        left: 3,
    },
});

export const leftBottomBlock = ({theme}) => ({
    ...leftHeaderBlock({theme}),
    ':before': {
        ...headerPseudo,
        height: 12,
        top: -11,
        left: 3,
    },
});
export const rightBottomBlock = ({theme}) => ({
    ...rightHeaderBlock({theme}),
    ':before': {
        ...headerPseudo,
        height: 12,
        top: -11,
        right: 3,
    },
});

export const header = ({theme}) => ({
    border: 0,
    float: 'left',
    marginTop: 35,
    marginBottom: 10,
    width: '100%',
    height: '12px',
    borderTop: '3px solid white',
    position: 'relative',
});
export const bottom = ({theme}) => ({
    ...header({theme}),
    marginBottom: 0,
});

export const headerText = ({ theme }) => ({
    display: 'block',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    margin: 'auto',
    textAlign: 'center',
    letterSpacing: '0.88px',
    lineHeight: '20px',
    fontFamily: theme.font.pixel,
    fontWeight: 'bold',
    fontSize: '20px',
    width: '140px',
    marginTop: -10,
    background: theme.colors.blue,
    height: 20,
});
