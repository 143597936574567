import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { connect, FelaComponent } from 'react-fela';
import { Rnd } from 'react-rnd';

import ObserveSize from 'react-observe-size';
import * as componentStyles from './Window.style';
import WindowHeader from './WindowHeader';
import Projects from './Projects/Projects';
import config from '../../config';
import * as windowTypes from '../../constants/icons';
import Editor from './Editor/Editor';
import arrowUp from '../../images/arrow_up.svg';
import arrowDown from '../../images/arrow_down.svg';
import Bin from './Bin/Bin';
import EmptyBinButton from './Bin/EmptyBinButton';
import Gallery from './Gallery/Gallery';
import Search from './Search/Search';
import SearchInput from '../Header/Search/SearchInput';
import AppContext from '../../context/AppContext';
import About from './About/About';
import IFrame from './IFrame/IFrame';

const { iconHeight, scrollStep, minScrollbarHeight } = config.navbar;

const Window = ({
    styles,
    type,
    desktopSize,
    focusWindow,
    isFocused,
    data,
    repoUrl,
    updateWindow,
    width,
    height,
    x,
    y,
    emptyBin,
    index,
    zIndex,
    dataId,
    closeWindow,
    ...props
}) => {
    const [headerSize, setHeaderSize] = useState(config.navbar.height);
    const [oldPosition, setOldPosition] = useState({ x, y });
    const [trackerPosition, setTrackerPosition] = useState(0);
    const [isMaximized, setMaximized] = useState(false);
    const [trackerSize, setTrackerSize] = useState(0);
    const [oldWindowSize, setOldWindowSize] = useState({ width, height });
    const [innerOuterHeightRatio, setInnerOuterHeightRatio] = useState(1);
    const [isDragging, setDragging] = useState(false);
    const [browserScrollbarWidth, setBrowserScrollbarWidth] = useState(0);

    /* Point to scrollable container */
    const wrapperRef = useRef(null);

    const handleScrollUp = e => {
        wrapperRef.current.scrollTop -= scrollStep;
    };
    const handleScrollDown = e => {
        wrapperRef.current.scrollTop += scrollStep;
    };

    const handleScroll = () => {
        setTrackerPosition(Math.ceil(wrapperRef.current.scrollTop / innerOuterHeightRatio));
    };

    /* Triggered from children component */
    const detectBrowserScrollbarWidth = useCallback(() => {
        if (wrapperRef.current) {
            return wrapperRef.current.offsetWidth - wrapperRef.current.clientWidth;
        }
        return 0;
    }, [wrapperRef]);

    const handleLoaded = useCallback(
        innerHeight => {
            if (wrapperRef.current && innerHeight > 0) {
                const actualVisibleSize = wrapperRef.current.getBoundingClientRect().height; // viewport of container
                // All content is visible
                let ratio = innerHeight / actualVisibleSize;
                if (actualVisibleSize === innerHeight) {
                    setTrackerSize(0);
                    setInnerOuterHeightRatio(1);
                } else {
                    if (ratio < 1) {
                        setTrackerSize(0);
                    } else {
                        let finalSize = Math.ceil(actualVisibleSize / ratio) - 2 * iconHeight;
                        if (finalSize < minScrollbarHeight) {
                            const diffInSizes = Math.abs(finalSize - minScrollbarHeight);
                            ratio = innerHeight / (actualVisibleSize - diffInSizes);
                            finalSize = minScrollbarHeight;
                        }
                        setTrackerSize(finalSize);
                    }
                    setInnerOuterHeightRatio(ratio);
                }
                setBrowserScrollbarWidth(detectBrowserScrollbarWidth());
            }
        },
        [wrapperRef, setTrackerSize, setInnerOuterHeightRatio, setBrowserScrollbarWidth, detectBrowserScrollbarWidth],
    );

    let rnd = null;

    /* Full size window */
    const handleMaximizeWindow = useCallback(() => {
        if (!rnd) {
            return;
        }
        /* Reset window size and position before maximize button was clicked */
        if (isMaximized) {
            const pos = {
                x: oldPosition.x,
                y: oldPosition.y,
            };
            const size = {
                width: oldWindowSize.width,
                height: oldWindowSize.height,
            };
            rnd.updateSize(size);
            rnd.updatePosition(pos);
        } else {
            /* Maximize window */
            const pos = {
                x: 0,
                y: 0,
            };
            setOldPosition({ x, y });
            setOldWindowSize({ width, height });
            rnd.updatePosition(pos);
            const size = {
                width: window.innerWidth,
                height: window.innerHeight - config.navbar.height,
            };
            rnd.updateSize(size);
            setTrackerSize(0);
        }
        setMaximized(!isMaximized);
    }, [isMaximized, oldPosition, oldWindowSize, rnd, x, y, width, height]);

    // Automaticly resize window to fullscreen if enabled
    useEffect(() => {
        const handleResize = () => {
            if (isMaximized) {
                rnd.updateSize({
                    width: window.innerWidth,
                    height: window.innerHeight - headerSize,
                });
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isMaximized, rnd, updateWindow, headerSize]);

    return (
        <FelaComponent style={componentStyles.windowWrapper} zIndex={zIndex} type={type}>
            {({ className }) => (
                <Rnd
                    ref={c => {
                        if (c) {
                            rnd = c;
                        }
                    }}
                    enableResizing={{ bottomRight: type !== windowTypes.ICON_TYPE_ABOUT }}
                    enableUserSelectHack={false}
                    default={{ x, y, width, height }}
                    className={className}
                    data-cy="window"
                    bounds="parent"
                    onClick={focusWindow}
                    cancel={`.${styles.content.split(' ').join('.')}`}
                    onResize={(e, direction, ref) => {
                        updateWindow({
                            width: ref.clientWidth,
                            height: ref.clientHeight,
                        });
                    }}
                    disableDragging={type === windowTypes.ICON_TYPE_ABOUT}
                    onDragStart={() => {
                        focusWindow();
                        setDragging(true);
                    }}
                    onDragStop={(e, pos) => {
                        // Add 1px offset or position will become invalid
                        x = pos.x > 0 ? pos.x : 1;
                        y = pos.y > 0 ? pos.y : 1;
                        updateWindow({
                            x,
                            y,
                        });
                        setDragging(false);
                    }}
                >
                    <FelaComponent style={componentStyles.window} type={type} isFocused={isFocused}>
                        <FelaComponent style={componentStyles.borderWrapper} type={type}>
                            <FelaComponent isFocused={isFocused} style={componentStyles.windowTop} />
                            <FelaComponent isFocused={isFocused} style={componentStyles.windowLeft} />
                            <FelaComponent isFocused={isFocused} style={componentStyles.windowRight} />
                            <FelaComponent isFocused={isFocused} style={componentStyles.windowBottom} />
                        </FelaComponent>
                        {type !== windowTypes.ICON_TYPE_ABOUT && (
                            <ObserveSize observerFn={headerRect => setHeaderSize(headerRect.height)}>
                                <WindowHeader
                                    {...{
                                        itemsCount: data instanceof Array ? data.length : -1,
                                        onMaximizeWindow: handleMaximizeWindow,
                                        isMaximized,
                                        ...(dataId === 'bin' && {
                                            itemsCount: data.length,
                                            extraComponent: () => <EmptyBinButton emptyBinAction={emptyBin} />,
                                        }),
                                        ...(dataId === 'search' && {
                                            extraComponent: () => {
                                                return (
                                                    <AppContext.Consumer>
                                                        {({ desktopState }) => (
                                                            <SearchInput
                                                                onUpdateData={newData => {
                                                                    updateWindow({
                                                                        data: newData,
                                                                    });
                                                                }}
                                                                notDeletedIconsNames={desktopState.icons
                                                                    .filter(icon => icon && !icon.isDeleted)
                                                                    .map(icon => icon.dataId)}
                                                            />
                                                        )}
                                                    </AppContext.Consumer>
                                                );
                                            },
                                        }),
                                        closeWindow,
                                        index,
                                        ...props,
                                    }}
                                />
                            </ObserveSize>
                        )}
                        <div className={styles.content}>
                            <div className={styles.wrapperOuter} onScroll={handleScroll}>
                                <FelaComponent
                                    style={componentStyles.wrapper}
                                    hasScrollbar={type !== windowTypes.ICON_TYPE_ABOUT}
                                    isDragging={isDragging}
                                    browserScrollbarWidth={browserScrollbarWidth}
                                    headerHeight={headerSize || 0}
                                    height={isMaximized ? window.innerHeight - config.navbar.height : height}
                                >
                                    {style => (
                                        <div className={style.className} ref={wrapperRef}>
                                            <ObserveSize observerFn={contentRect => handleLoaded(contentRect.height)}>
                                                {type === windowTypes.ICON_TYPE_PROJECTS && <Projects data={data} />}
                                                {type === windowTypes.ICON_TYPE_EDITOR && <Editor data={data} />}
                                                {type === windowTypes.ICON_TYPE_BIN && <Bin data={data} />}
                                                {type === windowTypes.ICON_TYPE_GALLERY && <Gallery data={data} />}
                                                {type === windowTypes.ICON_TYPE_SEARCH && <Search data={data} />}
                                                {type === windowTypes.ICON_TYPE_ABOUT && (
                                                    <About closeWindow={closeWindow} />
                                                )}
                                                {type === windowTypes.ICON_TYPE_IFRAME && (
                                                    <IFrame data={data} height={height - headerSize} />
                                                )}
                                            </ObserveSize>
                                        </div>
                                    )}
                                </FelaComponent>
                                {type !== windowTypes.ICON_TYPE_ABOUT && (
                                    <FelaComponent style={componentStyles.scrollbar} offsetTop={headerSize}>
                                        <button type="button" className={styles.buttonTop} onClick={handleScrollUp}>
                                            <img src={arrowUp} alt="up" />
                                        </button>
                                        <FelaComponent
                                            style={componentStyles.scrollbarContent}
                                            height={trackerSize}
                                            top={trackerPosition}
                                        />
                                        <button
                                            type="button"
                                            className={styles.buttonBottom}
                                            onClick={handleScrollDown}
                                        >
                                            <img src={arrowDown} alt="down" />
                                        </button>
                                    </FelaComponent>
                                )}
                            </div>
                        </div>
                    </FelaComponent>
                </Rnd>
            )}
        </FelaComponent>
    );
};

Window.propTypes = {
    dataId: PropTypes.string.isRequired,
    styles: PropTypes.shape({
        window: PropTypes.string.isRequired,
        borderWrapper: PropTypes.string.isRequired,
        windowTop: PropTypes.string.isRequired,
        windowBottom: PropTypes.string.isRequired,
        windowLeft: PropTypes.string.isRequired,
        windowRight: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        wrapperOuter: PropTypes.string.isRequired,
        scrollbar: PropTypes.string.isRequired,
        buttonTop: PropTypes.string.isRequired,
        buttonBottom: PropTypes.string.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
    desktopSize: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    }).isRequired,
    focusWindow: PropTypes.func.isRequired,
    isFocused: PropTypes.bool.isRequired,
    updateWindow: PropTypes.func.isRequired,
    emptyBin: PropTypes.func,
    data: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                repoUrl: PropTypes.string,
            }),
        ),
        PropTypes.shape({
            url: PropTypes.string,
        }),
    ]),
    repoUrl: PropTypes.string,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    zIndex: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    closeWindow: PropTypes.func.isRequired,
    type: PropTypes.oneOf([
        windowTypes.ICON_TYPE_GALLERY,
        windowTypes.ICON_TYPE_BIN,
        windowTypes.ICON_TYPE_EDITOR,
        windowTypes.ICON_TYPE_PROJECTS,
        windowTypes.ICON_TYPE_SEARCH,
        windowTypes.ICON_TYPE_ABOUT,
        windowTypes.ICON_TYPE_IFRAME,
    ]).isRequired,
};

Window.defaultProps = {
    repoUrl: '',
    data: [],
    emptyBin: undefined,
};

export default connect(componentStyles)(Window);
