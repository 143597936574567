import React, { useCallback } from 'react';
import fullScreenIcon from '../../images/fullscreen.svg';

export const isInFullscreen = () => {
    return Boolean(
        document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement,
    );
};

export const disableFullScreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
    }
};

export const enableFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
        /* Firefox */
        document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
        /* IE/Edge */
        document.documentElement.msRequestFullscreen();
    }
};

const FullScreen = props => {
    const handleClick = useCallback(() => (isInFullscreen() ? disableFullScreen() : enableFullScreen()), []);
    return (
        <button type="button" {...props} onClick={handleClick}>
            <img src={fullScreenIcon} alt="Fullscreen icon" />
        </button>
    );
};

export default FullScreen;
