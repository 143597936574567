const navBase = {
    listStyle: 'none',
    float: 'left',
    margin: 0,
};

const itemBase = {
    display: 'block',
    float: 'left',
    fontWeight: 700,
    margin: 0,
    fontSize: 16,
    userSelect: 'none',
};

const menuPadding = {
    paddingTop: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 10,
};

const buttonBase = {
    backgroundColor: 'unset',
    cursor: 'pointer',
    border: 0,
    outline: 0,
    ...menuPadding,
};

const block = bgColor => ({
    backgroundColor: bgColor,
    height: 3,
    width: '100%',
});

export const button = ({ theme }) => ({
    ...buttonBase,
});
export const leftCorner = ({ theme }) => ({
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 0,
    left: 0,
    top: 0,
    overflow: 'hidden',
});
export const line = ({ theme }) => ({
    backgroundColor: theme.colors.white,
    height: 'calc(100% - 15px)',
    width: '100%',
    borderLeft: `3px solid ${theme.colors.blue}`,
});
export const subNav = ({ theme, isOpened }) => ({
    ...navBase,
    zIndex: 999,
    position: 'absolute',
    top: 48,
    borderWidth: 0,
    borderRightWidth: 6,
    borderStyle: 'solid',
    borderColor: theme.colors.blue,
    display: isOpened ? 'block' : 'none',
});
export const blockLeftOne = ({ theme }) => ({
    ...block(theme.colors.white),
    marginLeft: 3,
    borderStyle: 'solid',
    borderColor: theme.colors.blue,
    borderWidth: 0,
    borderLeftWidth: 3,
    borderRightWidth: 3,
});
export const blockLeftTwo = ({ theme }) => ({
    ...block(theme.colors.blue),
    marginLeft: 6,
});
export const blockLeftThree = ({ theme }) => ({
    ...block(theme.colors.blue),
    marginLeft: 9,
});
export const subMenuWrapper = ({ theme }) => ({
    overflow: 'auto',
    borderStyle: 'solid',
    borderColor: theme.colors.blue,
    borderWidth: 0,
    borderLeftWidth: 3,
    paddingTop: 10,
    background: theme.colors.white,
    minWidth: 180,
    maxWidth: '100vw',
    maxWidth480: {
        minWidth: 100,
    },
});
export const subItem = ({ theme }) => ({
    ...itemBase,
    paddingTop: 2,
    paddingBottom: 2,
    width: '100%',
    position: 'relative',
    backgroundColor: theme.colors.white,
    zIndex: 50,
    '&:hover': {
        zIndex: 5,
        backgroundColor: theme.colors.blue,
        color: theme.colors.white,
    },
});

export const subItemLink = ({ theme }) => ({
    color: 'inherit',
    paddingLeft: 14,
    paddingRight: 14,
    textDecoration: 'none',
    overflow: 'auto',
    display: 'block',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    textAlign: 'left',
});
