import { useReducer } from 'react';
import * as constants from '../constants/system';

const initialState = {
    isBooting: true,
    isLoaded: false,
    isGoingShutdown: false,
    isRebooting: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case constants.SYSTEM_REBOOT: {
            return {
                ...state,
                isBooting: false,
                isLoaded: false,
                isGoingShutdown: true,
                isRebooting: true,
            };
        }
        case constants.SYSTEM_SHUTDOWN: {
            return {
                ...state,
                isBooting: false,
                isLoaded: false,
                isGoingShutdown: true,
                isRebooting: false,
            };
        }
        case constants.SYSTEM_LOADED: {
            return {
                ...state,
                isBooting: false,
                isLoaded: Boolean(action.loaded),
                isGoingShutdown: false,
                isRebooting: false,
            };
        }
        case constants.SYSTEM_BOOT: {
            return {
                ...state,
                isBooting: true,
                isLoaded: false,
                isGoingShutdown: false,
                isRebooting: false,
            };
        }
        default:
            throw new Error('Undefined action');
    }
};

export default () => useReducer(reducer, initialState);
