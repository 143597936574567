import config from '../../config';

export const container = ({ theme, doubleBar }) => ({
    borderBottom: `3px solid ${theme.colors.black}`,
    marginBottom: 0,
    height: (doubleBar ? config.window.navbar.height * 2 : config.window.navbar.height) + 9, // bottom black line height
    fontSize: 16,
    lineHeight: '23px',
    fontWeight: 500,
    position: 'relative',
    ...(doubleBar && {
        ':before': {
            content: '" "',
            position: 'absolute',
            top: config.window.navbar.height * 2,
            backgroundColor: theme.colors.black,
            width: '100%',
            height: 3,
            bottom: -9, // bottom black line height
            left: 0,
        },
    }),
    ':after': {
        content: '" "',
        position: 'absolute',
        top: config.window.navbar.height,
        backgroundColor: theme.colors.black,
        width: '100%',
        height: 3,
        bottom: -9, // bottom black line height
        left: 0,
    },
});

export const extraComponent = ({ theme, doubleBar }) => ({
    width: 275,
    display: 'flex',
    marginRight: 15,
    justifyContent: 'flex-end',
});

export const itemsCount = ({ theme, doubleBar, hasExtraComponent }) => ({
    ...(hasExtraComponent && {
        maxWidth480: {
            display: 'none',
        },
    }),
    textAlign: 'center',
    width: 200,
    maxWidth480: {
        width: 150,
    },
    backgroundColor: theme.colors.white,
    left: 0,
    right: 0,
    margin: '1px auto',
    display: 'block',
    position: 'absolute',
    overflow: 'hidden',
    fontWeight: '700',
    letterSpacing: '0.7px',
    fontSize: 16,
    lineHeight: '21px',
    padding: '10px 5px',
    ...(doubleBar && {
        top: config.window.navbar.height,
        lineHeight: `${config.window.navbar.height - 3}px`,
        padding: 0,
    }),
});

export const title = () => ({
    fontWeight: 800,
    fontSize: 16,
    textTransform: 'uppercase',
    marginBottom: 0,
    textAlign: 'left',
    float: 'left',
    display: 'block',
    padding: '14px 11px',
    letterSpacing: '0.7px',
});

export const rightMenu = () => ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '11px 4px 11px 6px',
});

export const icon = () => ({
    margin: '0 4px',
});
