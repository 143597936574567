export const DESKTOP_OPEN_WINDOW = 'DESKTOP_OPEN_WINDOW';
export const DESKTOP_OPEN_WINDOW_IN_CENTER = 'DESKTOP_OPEN_WINDOW_IN_CENTER';
export const DESKTOP_UPDATE_ICON = 'DESKTOP_UPDATE_ICON';
export const DESKTOP_REORDER_ICONS = 'DESKTOP_REORDER_ICONS';
export const DESKTOP_CLOSE_WINDOW = 'DESKTOP_CLOSE_WINDOW';
export const DESKTOP_CLOSE_ALL_WINDOWS = 'DESKTOP_CLOSE_ALL_WINDOWS';
export const REFRESH_SEARCH_WINDOW = 'REFRESH_SEARCH_WINDOW';
export const DESKTOP_MINIMIZE_WINDOW = 'DESKTOP_MINIMIZE_WINDOW';
export const DESKTOP_MAXIMIZE_WINDOW = 'DESKTOP_MAXIMIZE_WINDOW';
export const DESKTOP_FOCUS_WINDOW = 'DESKTOP_FOCUS_WINDOW';
export const DESKTOP_UPDATE_WINDOW = 'DESKTOP_UPDATE_WINDOW';
export const DESKTOP_EMPTY_BIN = 'DESKTOP_EMPTY_BIN';
export const UPDATE_GRID_SETTINGS = 'UPDATE_GRID_SETTINGS';

export const RESET_TO_INITIAL_STATE = 'RESET_TO_INITIAL_STATE';
