import React from 'react';
import {PropTypes} from 'prop-types';
import { FelaComponent, connect } from 'react-fela';
import {FormattedMessage} from 'gatsby-plugin-intl';
import * as stylesAll from './About.style';
import * as logo from "../../../images/about/logo.svg";
import PixelButton from "../../Button/PixelButton";

const About = ({styles, closeWindow}) => {
    return (
        <>
            <FelaComponent style={stylesAll.container}>
                <img src={logo} alt="Ackee logo" />
                <FormattedMessage id="about.title">
                    {txt => <h1>{txt}</h1>}
                </FormattedMessage>

                <div className={styles.header}>
                    <div className={styles.leftHeaderBlock} />
                    <FormattedMessage id="about.headerText">
                        {txt => <h1 className={styles.headerText}>{txt}</h1>}
                    </FormattedMessage>
                    <div className={styles.rightHeaderBlock} />
                </div>

                <div className={styles.systemInfo}>
                    <ul>
                        <li>Release Name –––––––––&gt; 23/02/2019</li>
                        <li>Release Type –––––––––&gt; Library</li>
                        <li>Release Date –––––––––&gt; Library</li>
                        <li>Kind –––––––––––––––&gt; Git</li>
                        <li>Format –––––––––––––&gt; xxx</li>
                    </ul>
                </div>

                <div className={styles.bottom}>
                    <div className={styles.leftBottomBlock} />
                    <div className={styles.rightBottomBlock} />
                </div>

            </FelaComponent>

            <FelaComponent style={stylesAll.bottomWrapper}>
                <FormattedMessage id="about.exit">
                    {txt => <PixelButton borderColor="white" onClick={closeWindow}>{txt}</PixelButton>}
                </FormattedMessage>
            </FelaComponent>
        </>
    );
};

About.propTypes = {
    closeWindow: PropTypes.func.isRequired,
    styles: PropTypes.shape({
        container: PropTypes.string,
        header: PropTypes.string,
        headerText: PropTypes.string,
        bottom: PropTypes.string,
        bottomText: PropTypes.string,
        bottomWrapper: PropTypes.string,
        leftHeaderBlock: PropTypes.string,
        leftBottomBlock: PropTypes.string,
        rightHeaderBlock: PropTypes.string,
        rightBottomBlock: PropTypes.string,
        systemInfo: PropTypes.string,
    }).isRequired,
};

export default connect(stylesAll)(About);
