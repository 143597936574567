import React from 'react';
import PropTypes from 'prop-types';
import { connect as connectFela, FelaComponent } from 'react-fela';
import * as stylesAll from './PixelButton.style';

const PixelButton = ({ onClick, children, overrideStyles, borderColor }) => (
    <FelaComponent style={[stylesAll.inner, overrideStyles]} borderColor={borderColor}>
        {({ className }) => (
            <button type="button" onClick={onClick} className={className}>
                <FelaComponent style={stylesAll.left} borderColor={borderColor}>
                    <FelaComponent style={stylesAll.dotLeft1} />
                    <FelaComponent style={stylesAll.dotLeft2} />
                    <FelaComponent style={stylesAll.dotLeft3} />
                    <FelaComponent style={stylesAll.dotLeft2} />
                    <FelaComponent style={stylesAll.dotLeft1} />
                </FelaComponent>
                <span>{children}</span>
                <FelaComponent style={stylesAll.right} borderColor={borderColor}>
                    <FelaComponent style={stylesAll.dotLeft2} />
                    <FelaComponent style={stylesAll.dotLeft1} />
                    <FelaComponent style={stylesAll.dotRight3} />
                    <FelaComponent style={stylesAll.dotLeft1} />
                    <FelaComponent style={stylesAll.dotLeft2} />
                </FelaComponent>
            </button>
        )}
    </FelaComponent>
);

PixelButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    borderColor: PropTypes.string,
    overrideStyles: PropTypes.func,
    children: PropTypes.node.isRequired,
};

PixelButton.defaultProps = {
    borderColor: `#000000`,
    overrideStyles: () => {},
};

export default connectFela(stylesAll)(PixelButton);
