import { useReducer } from 'react';
import { get } from 'lodash';
import desktopData from '../components/Desktop/Desktop.data';
import * as constants from '../constants/desktop';
import config from '../config';

const initialState = {};

const reducer = (state, action) => {
    switch (action.type) {
        case constants.DESKTOP_OPEN_WINDOW_IN_CENTER: {
            const windowWidth = action.window.width || config.window.width;
            const windowHeight = action.window.height || config.window.height;
            return reducer(state, {
                type: constants.DESKTOP_OPEN_WINDOW,
                window: {
                    ...action.window,
                    x: window.innerWidth / 2 - windowWidth / 2,
                    y: (window.innerHeight - config.navbar.height) / 2 - windowHeight / 2,
                },
            });
        }
        case constants.DESKTOP_OPEN_WINDOW: {

            let { defaultWidth, defaultHeight } = config.window;

            if (action.window.width) {
                defaultWidth = action.window.width;
            }
            if (action.window.height) {
                defaultHeight = action.window.height;
            }

            // Check if window has required size to fit window
            if (defaultWidth > window.innerWidth) {
                defaultWidth = window.innerWidth;
            }
            if (defaultHeight > window.innerHeight) {
                defaultHeight = window.innerHeight - config.navbar.height;
            }

            // If window is already opened -> focus it and do not open new one
            const windowIndex = state.windows.findIndex(window => window.dataId === action.window.dataId);
            if (windowIndex > -1) {
                // The window can be minimized
                if (state.windows[windowIndex].isMinimized) {
                    return reducer(state, {
                        type: constants.DESKTOP_MAXIMIZE_WINDOW,
                        index: windowIndex,
                    });
                }
                return reducer(state, {
                    type: constants.DESKTOP_FOCUS_WINDOW,
                    index: windowIndex,
                });
            }

            // Bottom bounds
            if (action.window.x + defaultWidth > window.innerWidth) {
                action.window.x -= defaultWidth;
            }
            if (action.window.y + defaultHeight > window.innerHeight - config.navbar.height) {
                action.window.y -= defaultHeight;
            }

            // Top bounds
            if (action.window.x < 0) {
                action.window.x = 0;
            }
            if (action.window.y < 0) {
                action.window.y = 0;
            }

            const windows = state.windows.map(window => ({ ...window, isFocused: false }));
            windows.push({ ...action.window, isFocused: true, zIndex: state.windowsMaxZIndex + 1, width: defaultWidth, height: defaultHeight });
            return {
                ...state,
                windows,
                windowsMaxZIndex: state.windowsMaxZIndex + 1,
            };
        }
        case constants.DESKTOP_UPDATE_ICON: {
            const newIcon = { ...state.icons[action.index] };
            if (action.key) {
                newIcon[action.key] = action.val;
            }
            return {
                ...state,
                icons: [...state.icons.slice(0, action.index), newIcon, ...state.icons.slice(action.index + 1)],
            };
        }
        case constants.DESKTOP_EMPTY_BIN: {
            return {
                ...state,
                icons: state.icons.filter(icon => !icon.isDeleted),
            };
        }
        case constants.DESKTOP_REORDER_ICONS: {
            return { ...state, icons: action.icons };
        }
        case constants.REFRESH_SEARCH_WINDOW: {
            const notDeletedIconsNames = state.icons.filter(icon => icon && !icon.isDeleted).map(icon => icon.dataId);
            return {
                ...state,
                windows: state.windows.map(window => {
                    if (window.dataId === 'search') {
                        window.data = window.data.filter(projectIcon => notDeletedIconsNames.indexOf(projectIcon.iconDataId) > -1);
                    }
                    return window;
                })
            }
        }
        case constants.DESKTOP_CLOSE_WINDOW: {
            return {
                ...state,
                windowsMaxZIndex: state.windowsMaxZIndex - 1,
                windows: state.windows
                    .filter(item => item.dataId !== action.dataId)
                    .map(window => ({
                        ...window,
                        isFocused: window.zIndex === state.windowsMaxZIndex - 1,
                        zIndex: window.zIndex > action.index ? window.zIndex - 1 : window.zIndex,
                    })),
            };
        }
        case constants.DESKTOP_CLOSE_ALL_WINDOWS: {
            return {
                ...state,
                windowsMaxZIndex: initialState.windowsMaxZIndex,
                windows: []
            };
        }
        case constants.DESKTOP_MINIMIZE_WINDOW: {
            const newState = {
                ...state,
                windows: state.windows.map((window, index) => {
                    if (window.isMinimized) {
                        return window;
                    }
                    if (window.zIndex > action.index) {
                        return {
                            ...window,
                            isFocused: false,
                            zIndex: window.zIndex - 1,
                            isMinimized: index === action.index,
                        };
                    }
                    return {
                        ...window,
                        isFocused: false,
                        zIndex: window.zIndex - 1,
                    };
                }),
                windowsMaxZIndex: state.windowsMaxZIndex - 1,
            };
            // We set focus to another window
            if (newState.windows.length - 1 > 0) {
                const findHighestZIndex = () => {
                    let highestZIndex = 0;
                    let maxIndex;
                    newState.windows.forEach((window, index) => {
                        if (window.zIndex > highestZIndex) {
                            highestZIndex = window.zIndex;
                            maxIndex = index;
                        }
                    });
                    return maxIndex;
                };
                return reducer(newState, {
                    type: constants.DESKTOP_FOCUS_WINDOW,
                    index: findHighestZIndex(),
                });
            }
            return newState;
        }
        case constants.DESKTOP_MAXIMIZE_WINDOW: {
            // Maximize window
            const newState = {
                ...state,
                windows: state.windows.map((window, index) => {
                    if (action.index !== index) {
                        return { ...window, isFocused: false };
                    }
                    return {
                        ...window,
                        isFocused: true,
                        isMinimized: false,
                        zIndex: state.windowsMaxZIndex + 1,
                    };
                }),
                windowsMaxZIndex: state.windowsMaxZIndex + 1,
            };
            // And focus it
            return reducer(newState, {
                type: constants.DESKTOP_FOCUS_WINDOW,
                index: action.index,
            });
        }
        case constants.DESKTOP_FOCUS_WINDOW: {
            if (get(state.windows[action.index], 'zIndex', -1) === state.windowsMaxZIndex) {
                return state;
            }

            // Decrement zIndex on currently focused window
            return {
                ...state,
                windows: state.windows.map((window, index) => ({
                    ...window,
                    isFocused: index === action.index,
                    zIndex: (() => {
                        if (index === action.index) {
                            return state.windowsMaxZIndex;
                        }
                        if (window.zIndex === state.windowsMaxZIndex) {
                            return window.zIndex - 1;
                        }
                        return window.zIndex;
                    })(),
                })),
            };
        }
        case constants.DESKTOP_UPDATE_WINDOW: {
            if (!state.windows || !state.windows[action.index]) {
                console.error("Window not found");
                return state;
            }
            const newWindow = { ...state.windows[action.index], ...action.obj };
            return {
                ...state,
                windows: [...state.windows.slice(0, action.index), newWindow, ...state.windows.slice(action.index + 1)],
            };
        }
        case constants.UPDATE_GRID_SETTINGS: {
            return {
                ...state,
                gridEnabled: action.enabled,
            };
        }
        case constants.RESET_TO_INITIAL_STATE: {
            return {
                windows: [],
                icons: desktopData,
                gridEnabled: false,
                windowsMaxZIndex: 10,
            }
        }
        default:
            throw new Error('Undefined action');
    }
};

export default () => useReducer(reducer, initialState);
