import React from 'react';
import { PropTypes } from 'prop-types';
import { FelaComponent } from 'react-fela';
import * as componentStyles from './Gallery.style';

const Gallery = ({ data }) => {
    return (
        <FelaComponent style={componentStyles.container}>
            {data.map(photo => (
                <FelaComponent style={componentStyles.photo} key={photo.src}>
                    {({ className }) => <img src={photo.src} alt={photo.alt} className={className} />}
                </FelaComponent>
            ))}
        </FelaComponent>
    );
};

Gallery.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
};

Gallery.defaultProps = {
    data: [],
};

export default Gallery;
