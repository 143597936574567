import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useClickAlerter = (ref, onOutsideClick, onWrapperClick, onInsideClick, deps) => {
    const handleClickOutsideWrapper = useCallback(
        event => {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick(event);
            }
        },
        [onOutsideClick, ref],
    );
    const handleClickInsideWrapper = useCallback(
        event => {
            if (ref.current && ref.current.contains(event.target)) {
                onInsideClick(event);
            }
        },
        [onInsideClick, ref],
    );
    const handleClickOnWrapper = useCallback(
        event => {
            if (ref.current && ref.current === event.target) {
                onWrapperClick(event);
            }
        },
        [onWrapperClick, ref],
    );
    useEffect(() => {
        if (onOutsideClick) {
            document.addEventListener('mousedown', handleClickOutsideWrapper);
        }
        if (onOutsideClick) {
            document.addEventListener('mousedown', handleClickOnWrapper);
        }
        if (onInsideClick) {
            document.addEventListener('mousedown', handleClickInsideWrapper);
        }
        return () => {
            if (onOutsideClick) {
                document.removeEventListener('mousedown', handleClickOutsideWrapper);
            }
            if (onOutsideClick) {
                document.removeEventListener('mousedown', handleClickOnWrapper);
            }
            if (onInsideClick) {
                document.removeEventListener('mousedown', handleClickInsideWrapper);
            }
        };
    }, [
        handleClickOnWrapper,
        handleClickOutsideWrapper,
        deps,
        handleClickInsideWrapper,
        onOutsideClick,
        onInsideClick,
    ]);
};

const ClickAlerter = ({ children, onOutsideClick, onWrapperClick, onInsideClick, deps, ...props }) => {
    const wrapperRef = useRef(null);
    useClickAlerter(wrapperRef, onOutsideClick, onWrapperClick, onInsideClick, deps);
    return (
        <div ref={wrapperRef} {...props}>
            {children}
        </div>
    );
};

ClickAlerter.propTypes = {
    onOutsideClick: PropTypes.func,
    onWrapperClick: PropTypes.func,
    onInsideClick: PropTypes.func,
    children: PropTypes.element.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    deps: PropTypes.array.isRequired,
};

ClickAlerter.defaultProps = {
    onInsideClick: undefined,
    onOutsideClick: undefined,
    onWrapperClick: undefined,
};

export default ClickAlerter;
