import PropTypes from 'prop-types';
import React from 'react';
import { connect as connectFela } from 'react-fela';
import * as stylesAll from './Header.style';
import Navbar from './Navbar';

const Header = ({ styles }) => {
    return (
        <header className={styles.header}>
            <div className={styles.leftCorner}>
                <div className={styles.line} />
                <div className={styles.blockLeftOne} />
                <div className={styles.blockLeftTwo} />
                <div className={styles.blockLeftThree} />
                <div className={styles.blockLeftFour} />
            </div>

            <div className={styles.rightCorner}>
                <div className={styles.blockRightOne} />
                <div className={styles.blockRightTwo} />
                <div className={styles.blockRightThree} />
                <div className={styles.blockRightFour} />
                <div className={styles.lineRight} />
            </div>

            <div className={styles.headerInner}>
                <Navbar />
            </div>
        </header>
    );
};

Header.propTypes = {
    styles: PropTypes.shape({
        header: PropTypes.string,
        content: PropTypes.string,
        title: PropTypes.string,
        leftCorner: PropTypes.string,
        rightCorner: PropTypes.string,
        line: PropTypes.string,
        blockLeftOne: PropTypes.string,
        blockLeftTwo: PropTypes.string,
        blockLeftThree: PropTypes.string,
        blockLeftFour: PropTypes.string,
        blockRightOne: PropTypes.string,
        blockRightTwo: PropTypes.string,
        blockRightThree: PropTypes.string,
        blockRightFour: PropTypes.string,
        lineRight: PropTypes.string,
        headerInner: PropTypes.string,
    }).isRequired,
};

export default connectFela(stylesAll)(Header);
