export const wrapper = () => ({
    margin: '40px auto 10px'
});

export const button = () => ({
    background: 'transparent',
    border: 'none',
    padding: 0,
    margin: 0,
    overflow: 'auto',
    cursor: 'pointer'
});

