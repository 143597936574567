export const wrapper = ({ theme }) => ({
    backgroundColor: theme.colors.blue,
    width: '100%',
    height: '100%',
    position: 'absolute',
});

export const progressWrapper = ({ theme }) => ({
    width: '100%',
    float: 'left',
});

export const innerWrapper = ({ theme }) => ({
    position: 'relative',
    top: '75%',
    transform: 'translateY(-75%)',
    maxWidth: 426,
    margin: '0 auto',
    float: 'none',
    overflow: 'auto',
    fontWeight: 'bold',
    '@media(max-height: 800px)': {
        top: '50%',
        transform: 'translateY(-50%)',
        padding: '0 16px',
    },
});

export const logoHref = ({ theme }) => ({
    border: 0,
    display: 'block',
    overflow: 'auto',
});

export const logo = ({ theme }) => ({
    margin: '0 auto',
    float: 'none',
    display: 'block',
    width: '100%',
});

export const progressText = ({ theme }) => ({
    textAlign: 'center',
    display: 'block',
    color: theme.colors.white,
    fontFamily: theme.font.pixel,
    marginTop: 20,
});

export const systemInfo = ({ theme }) => ({
    width: '100%',
    display: 'block',
    clear: 'both',
    float: 'left',
    color: theme.colors.white,
    borderLeft: `2px solid ${theme.colors.white}`,
    borderRight: `2px solid ${theme.colors.white}`,
    borderBottom: `2px solid ${theme.colors.white}`,
    marginBottom: 30,
    '>ul': {
        padding: '40px 15px',
        fontWeight: 'bold',
        listStyle: 'none',
        margin: '0 auto',
        width: 380,
        maxWidth: '100%',
        display: 'block',
        '>li': {
            lineHeight: 1.65,
            marginBottom: '0',
        },
    },
});
