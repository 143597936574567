import * as constants from '../../constants/icons';
import config from "../../config";

const { width, height } = config.window;

export default {
    ABOUT: {
        dataId: 'about',
        titleKey: 'navbar.icons.about',
        type: constants.ICON_TYPE_ABOUT,
        width: 550,
        height: 550,
    },
    GALLERY: {
        dataId: 'gallery',
        titleKey: 'navbar.icons.gallery',
        type: constants.ICON_TYPE_GALLERY,
        width,
        height,
    },
    SEARCH: {
        dataId: 'search',
        titleKey: 'navbar.icons.search',
        type: constants.ICON_TYPE_SEARCH,
        width,
        height,
        doubleBar: true,
    },
    CAREER: {
        dataId: 'career',
        titleKey: 'navbar.icons.career',
        type: constants.ICON_TYPE_IFRAME,
        width,
        height,
        data: {
            url: "https://www.ackee.cz/kariera"
        }
    },
};
