import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';

const useGalleriesQuery = () => {
    const data = useStaticQuery(graphql`
        query {
            allGalleriesJson {
                edges {
                    node {
                        id
                        photos {
                            title
                            alt
                            src
                        }
                    }
                }
            }
        }
    `);

    return get(data, ['allGalleriesJson', 'edges'], []).reduce((acc, edge) => {
        acc[edge.node.id] = {};
        Object.keys(edge.node).forEach(key => {
            if (key !== 'id') {
                acc[edge.node.id] = { ...acc[edge.node.id], [key]: edge.node[key] };
            }
        });
        return acc;
    }, {});
};

export default useGalleriesQuery;
