const navBase = {
    listStyle: 'none',
    float: 'left',
    margin: 0,
};

const itemBase = {
    display: 'block',
    float: 'left',
    fontWeight: 700,
    margin: 0,
    fontSize: 16,
    userSelect: 'none',
};

const menuPadding = {
    paddingTop: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 10,
};

const buttonBase = {
    backgroundColor: 'unset',
    cursor: 'pointer',
    border: 0,
    outline: 0,
    ...menuPadding,
};

const block = bgColor => ({
    backgroundColor: bgColor,
    height: 3,
    width: '100%',
});

export const wrapper = ({ theme, isActive }) => ({
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: isActive ? 5 : 1,
});

export const button = ({ theme }) => ({
    ...buttonBase,
});
export const leftCorner = ({ theme }) => ({
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 0,
    left: 0,
    top: 0,
    overflow: 'hidden',
});
export const line = ({ theme }) => ({
    backgroundColor: theme.colors.white,
    height: 'calc(100% - 15px)',
    width: '100%',
    borderLeft: `2px solid ${theme.colors.black}`,
});
export const subNav = ({ theme, position }) => ({
    ...navBase,
    zIndex: 5,
    position: 'absolute',
    top: position.y - 50,
    left: position.x,
    borderStyle: 'solid',
    borderColor: theme.colors.black,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 3,
    borderRightWidth: 3,
    marginTop: 3,
    marginLeft: 3,
    transform: 'translate3d(0,0,0)',
    overflow: 'visible',
});
export const blockLeftOne = ({ theme }) => ({
    ...block(theme.colors.white),
    marginLeft: 3,
    borderStyle: 'solid',
    borderColor: theme.colors.black,
    borderTop: 0,
    borderBottom: 0,
    borderLeftWidth: 3,
    borderRightWidth: 3,
});
export const blockLeftTwo = ({ theme }) => ({
    ...block(theme.colors.black),
    marginLeft: 6,
});
export const blockLeftThree = ({ theme }) => ({
    ...block(theme.colors.black),
    marginLeft: 9,
});
export const desktopSubMenuWrapper = ({ theme }) => ({
    overflow: 'auto',
    borderStyle: 'solid',
    borderColor: theme.colors.black,
    borderWidth: 0,
    borderLeftWidth: 1,
    background: theme.colors.white,
    maxWidth: '100vw',
});
export const subItem = ({ theme }) => ({
    ...itemBase,
    paddingTop: 2,
    paddingBottom: 2,
    width: 'auto',
    float: 'none',
    position: 'relative',
    backgroundColor: theme.colors.white,
    zIndex: 50,
    minWidth: 150,
    '&:hover': {
        zIndex: 5,
        backgroundColor: theme.colors.blue,
        color: theme.colors.white,
    },
    ':last-child': {
        ':hover': {
            ':after': {
                content: '" "',
                background: theme.colors.blue,
                display: 'block',
                height: '5px',
                bottom: -2,
                width: 'calc(100% - 3px)',
                position: 'fixed',
                left: 3,
            },
        },
    }
});

export const subItemLink = ({ theme }) => ({
    color: 'inherit',
    paddingLeft: 14,
    paddingRight: 14,
    textDecoration: 'none',
    overflow: 'auto',
    display: 'block',
    background: 'none',
    border: 'none',
    outline: 'none',
    width: '100%',
    textAlign: 'left',
    boxSizing: 'border-box',
    '-webkit-appearance': 'none',
});
