import config from '../config';

export default {
    font: {
        family: 'Greycliff, Verdana, sans-serif',
        pixel: 'Pixel, monospace',
    },
    colors: {
        black: '#000',
        red: '#ED6B65',
        redDark: '#A42115',
        white: '#FFF',
        primary: 'rebeccapurple',
        blue: 'blue',
        darkBlue: '#00109F',
        pink: '#FF00FF',
    },
    icons: {
        width: config.icons.width,
        widthSmall: config.icons.width - 50,
        height: config.icons.height,
        heightSmall: config.icons.height - 50,
    },
};
