import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { connect as connectFela } from 'react-fela';
import * as stylesAll from './EmptyBinButton.style';
import { DialogConsumer } from '../../../context/DialogContext';
import PixelButton from '../../Button/PixelButton';
import { DIALOG_INFO, DIALOG_WARNING } from '../../../constants/DialogTypes';

const EmptyBinButton = ({ emptyBinAction }) => (
    <DialogConsumer>
        {({ openDialog }) => (
            <FormattedMessage id="desktop.settings.button.empty">
                {txt => (
                    <PixelButton onClick={() => openDialog(DIALOG_WARNING, 'dialog.emptyBin', emptyBinAction)}>
                        {txt}
                    </PixelButton>
                )}
            </FormattedMessage>
        )}
    </DialogConsumer>
);

EmptyBinButton.propTypes = {
    emptyBinAction: PropTypes.func.isRequired,
};

export default connectFela(stylesAll)(EmptyBinButton);
