import PropTypes from 'prop-types';
import React from 'react';
import { connect as connectFela, FelaComponent } from 'react-fela';
import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import * as stylesAll from './SubMenu.style';
import AppContext from '../../context/AppContext';
import { DialogConsumer } from '../../context/DialogContext';
import SubMenuItem from './SubMenuItem';

const SubMenu = ({ isOpened, items, itemName, onClick }) => (
    <AppContext.Consumer>
        {({ desktopDispatch, systemDispatch }) => (
            <FelaComponent style={stylesAll.subNav} isOpened={isOpened}>
                <FelaComponent style={stylesAll.subMenuWrapper}>
                    {items.map(subItem => (
                        <FelaComponent as="li" key={`submenu-${itemName}-${subItem.id}`} style={stylesAll.subItem}>
                            <FelaComponent style={stylesAll.subItemLink}>
                                {({ className }) => (
                                    <>
                                        {subItem.link && (
                                            <Link to={subItem.link} className={className}>
                                                <FormattedMessage id={subItem.id} />
                                            </Link>
                                        )}
                                        {subItem.action && !subItem.withDialog && (
                                            <SubMenuItem
                                                item={subItem}
                                                desktopDispatch={desktopDispatch}
                                                systemDispatch={systemDispatch}
                                                className={className}
                                                onClick={onClick}
                                            />
                                        )}
                                        {subItem.action && subItem.withDialog && (
                                            <DialogConsumer>
                                                {({ openDialog }) => (
                                                    <SubMenuItem
                                                        item={subItem}
                                                        desktopDispatch={desktopDispatch}
                                                        systemDispatch={systemDispatch}
                                                        openDialog={openDialog}
                                                        className={className}
                                                        onClick={onClick}
                                                    />
                                                )}
                                            </DialogConsumer>
                                        )}
                                    </>
                                )}
                            </FelaComponent>
                        </FelaComponent>
                    ))}
                </FelaComponent>
                <FelaComponent style={stylesAll.leftCorner}>
                    <FelaComponent style={stylesAll.blockLeftOne} />
                    <FelaComponent style={stylesAll.blockLeftTwo} />
                    <FelaComponent style={stylesAll.blockLeftThree} />
                </FelaComponent>
            </FelaComponent>
        )}
    </AppContext.Consumer>
);

SubMenu.propTypes = {
    isOpened: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
    itemName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default connectFela(stylesAll)(SubMenu);
