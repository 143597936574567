const itemBase = {
    display: 'block',
    float: 'left',
    fontWeight: 700,
    margin: 0,
    fontSize: 16,
    userSelect: 'none',
};

const menuPadding = {
    paddingTop: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 10,
    maxWidth767: {
        paddingLeft: 8,
        paddingRight: 8,
    },
};

const buttonBase = {
    backgroundColor: 'unset',
    cursor: 'pointer',
    border: 0,
    outline: 0,
    ...menuPadding,
};

export const innerWrapper = ({ theme }) => ({
    float: 'left',
    overflow: 'auto',
});
export const button = ({ theme }) => ({
    ...buttonBase,
});
export const buttonLink = ({ theme, isOpened }) => {
    const def = {
        ...buttonBase,
        '&:hover': {
            color: theme.colors.white,
            backgroundColor: theme.colors.blue,
        },
    };
    if (isOpened) {
        return {
            ...def,
            color: theme.colors.white,
            backgroundColor: theme.colors.blue,
        };
    }
    return def;
};
export const item = ({ theme, isOpened }) => {
    const def = {
        ...itemBase,
        padding: 0,
        '&:hover': {
            color: theme.colors.white,
            backgroundColor: theme.colors.blue,
        },
    };
    if (isOpened) {
        return {
            ...def,
            color: theme.colors.white,
            backgroundColor: theme.colors.blue,
        };
    }
    return def;
};
