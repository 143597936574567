export const contentWrapper = ({ theme }) => ({
    margin: 0,
    padding: 0,
    fontFamily: theme.font.family,
});
export const appWrapper = ({ theme }) => ({
    overflow: 'hidden',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
});
