export const container = ({ theme, height }) => ({
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    border: 'none',
    '>iframe': {
        width: '100%',
        position: 'relative',
        margin: 0,
        border: 0,
        top: 0,
        left: 0,
    },
});

export const photo = () => ({
    width: '100%',
    display: 'block',
    float: 'left',
});
