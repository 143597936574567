import React, { useCallback, useEffect, useState } from 'react';
import { connect as connectFela } from 'react-fela';
import PropTypes from 'prop-types';
import elasticlunr from 'elasticlunr';
import * as stylesAll from './SearchInput.style';
import useSearchQuery from './Search.query';

elasticlunr.clearStopWords();

const SearchInput = ({ styles, onUpdateData, notDeletedIconsNames }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [allowedData, setAllowedData] = useState([]);
    const [allowedDataIndex, setAllowedDataIndex] = useState(false);
    const allProjects = useSearchQuery();

    /**
     * Parse data
     * Create search index
     */
    useEffect(() => {

        // Prepare index
        const index = elasticlunr(function() {
            this.addField('title');
            this.addField('description');
            this.addField('iconName');
            this.setRef('title');
        });

        const data = allProjects
            .filter(node => notDeletedIconsNames.indexOf(node.id) > -1)
            .reduce((acc, node) => {
                Object.keys(node).forEach(key => {
                    if (key !== 'id') {
                        const items = [...node[key]];
                        items.forEach(item => {
                            item.iconDataId = node.id;
                            // add item to index
                            index.addDoc(item);
                            acc.push(item);
                        });
                    }
                });
                return acc;
            }, []);

        setAllowedData(data);
        setAllowedDataIndex(index);
    }, [notDeletedIconsNames, allProjects, setAllowedData, setAllowedDataIndex]);

    /**
     * Init data (only first time)
     */
    useEffect(() => {
        if (!isLoaded && allowedData.length) {
            setIsLoaded(true);
            onUpdateData(allowedData);
        }
    }, [isLoaded, setIsLoaded, allowedData, onUpdateData]);

    /**
     * Change data based on user filter
     */
    const handleChange = useCallback(
        e => {
            const searchExpression = e.target.value.trim();

            // reset filter
            if (searchExpression === '') {
                return onUpdateData(allowedData);
            }

            const filteredData = allowedDataIndex
                .search(searchExpression, {
                    fields: {
                        title: { boost: 2 },
                        description: { boost: 1 },
                    },
                    expand: true, // suggestions
                })
                .map(result => allowedData.find(item => item.title === result.ref));

            return onUpdateData(filteredData);
        },
        [allowedData, allowedDataIndex, onUpdateData],
    );

    return <input type="text" onChange={handleChange} className={styles.wrapper} />;
};

SearchInput.propTypes = {
    notDeletedIconsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    onUpdateData: PropTypes.func.isRequired,
    styles: PropTypes.shape({
        wrapper: PropTypes.string,
        input: PropTypes.string,
    }).isRequired,
};

export default connectFela(stylesAll)(SearchInput);
