import React, { useEffect, useState } from 'react';
import { intlShape } from 'react-intl';
import { compose } from 'recompose';
import { connect as connectFela } from 'react-fela';
import { injectIntl } from 'gatsby-plugin-intl';
import * as stylesAll from './CurrentTime.style';

const TRANS_PATH = 'header.days.';
const DAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const formatDate = (date, intl, delimiterClass) => {
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    return (
        <>
            {intl.formatMessage({ id: `${TRANS_PATH}${DAYS[date.getDay()]}` })}
            {' '}
            {hours}
            <span className={delimiterClass}>:</span>
            {minutes}
        </>
    );
};

const CurrentTime = ({ styles, intl }) => {
    const [currentDate, setCurrentTime] = useState(new Date());
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setCurrentTime(new Date());
        }, (60 - currentDate.getSeconds()) * 1000);
        return () => clearTimeout(timeoutId);
    }, [currentDate]);
    return formatDate(currentDate, intl, styles.delimiter);
};

CurrentTime.propTypes = {
    intl: intlShape.isRequired,
};

export default compose(
    connectFela(stylesAll),
    injectIntl,
)(CurrentTime);
