import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

const SubMenuItem = ({ item, desktopDispatch, systemDispatch, openDialog, onClick, ...props }) => {
    const handleClick = useCallback(() => {
        if (item.action) {
            item.action(desktopDispatch, systemDispatch, openDialog);
            onClick();
        } else {
            throw new Error('Action not implemented');
        }
    }, [desktopDispatch, systemDispatch, openDialog, item]);

    return (
        <button type="button" onClick={handleClick} onContextMenu={e => e.preventDefault()} {...props}>
            <FormattedMessage id={item.id} />
        </button>
    );
};

SubMenuItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        action: PropTypes.func,
    }).isRequired,
    desktopDispatch: PropTypes.func.isRequired,
    systemDispatch: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    openDialog: PropTypes.func,
};

SubMenuItem.defaultProps = {
    openDialog: undefined,
};

export default SubMenuItem;
