export const container = () => ({
    maxWidth: 620,
    margin: '70px auto',
    // Scrollbar width is 30px
    width: '100%',
    float: 'none',
    padding: '32px 64px',
    clear: 'both',
    '::after': {
        clear: 'both',
        content: '""',
        display: 'table',
    },
    '>div': {
        maxWidth: 520,
        overflow: 'auto',
    },
    '>p': {
        maxWidth: 400,
        margin: '0 auto',
        ':first-child': {
            maxWidth: 700,
        },
        '>img': {
            display: 'block',
            margin: '0 auto 80px',
            width: '100%',
            maxWidth: 607,
        },
    },
    '>ul': {
        maxWidth: 400,
        fontWeight: '600',
        listStyle: 'none',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '40px',
        marginBottom: '80px',
        display: 'block',
        '>li': {
            marginBottom: 8,
            letterSpacing: '0.9px',
            lineHeight: '23px',
        }
    },
});
