import * as constants from '../constants/desktop';

export const openWindow = window => ({
    type: constants.DESKTOP_OPEN_WINDOW,
    window,
});

export const openWindowInCenter = window => ({
    type: constants.DESKTOP_OPEN_WINDOW_IN_CENTER,
    window,
});

export const updateIcon = (index, key, val) => ({
    type: constants.DESKTOP_UPDATE_ICON,
    index,
    key,
    val,
});

export const reorderIcons = icons => ({
    type: constants.DESKTOP_REORDER_ICONS,
    icons,
});

export const closeWindow = dataId => ({
    type: constants.DESKTOP_CLOSE_WINDOW,
    dataId,
});

export const closeAllWindows = dataId => ({
    type: constants.DESKTOP_CLOSE_ALL_WINDOWS,
    dataId,
});

export const refreshSearchWindow = () => ({
    type: constants.REFRESH_SEARCH_WINDOW,
});

export const minimizeWindow = index => ({
    type: constants.DESKTOP_MINIMIZE_WINDOW,
    index,
});

export const maximizeWindow = index => ({
    type: constants.DESKTOP_MAXIMIZE_WINDOW,
    index,
});

export const focusWindow = index => ({
    type: constants.DESKTOP_FOCUS_WINDOW,
    index,
});
export const updateWindow = (index, obj) => ({
    type: constants.DESKTOP_UPDATE_WINDOW,
    index,
    obj,
});
export const emptyBin = () => ({
    type: constants.DESKTOP_EMPTY_BIN,
});
export const gridSettings = enabled => ({
    type: constants.UPDATE_GRID_SETTINGS,
    enabled,
});

export const resetToInitialState = () => ({
    type: constants.RESET_TO_INITIAL_STATE,
});
