import React from 'react';
import PropTypes from 'prop-types';
import { connect as connectFela, FelaComponent } from 'react-fela';
import { FormattedMessage } from 'gatsby-plugin-intl';
import * as stylesAll from './TopMenuItem.style';
import SubMenu from './SubMenu';

const TopMenuItem = ({ itemName, items, isOpened, setItemOpened, children, onMouseEnter, onMouseLeave }) => (
    <FelaComponent style={stylesAll.innerWrapper} isOpened={isOpened}>
        <FelaComponent style={stylesAll.item} as="li">
            <FelaComponent style={stylesAll.buttonLink} isOpened={isOpened}>
                {({ className }) => (
                    <button
                        className={className}
                        type="button"
                        {...(items &&
                            items.length > 0 && {
                                onClick: () => setItemOpened(!isOpened ? itemName : ''),
                            })}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        onContextMenu={e => e.preventDefault()}
                    >
                        {children || <FormattedMessage id={itemName} />}
                    </button>
                )}
            </FelaComponent>
        </FelaComponent>
        {items && items.length > 0 && (
            <SubMenu isOpened={isOpened} items={items} itemName={itemName} onClick={() => setItemOpened(false)} />
        )}
    </FelaComponent>
);

TopMenuItem.propTypes = {
    itemName: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
    isOpened: PropTypes.bool.isRequired,
    setItemOpened: PropTypes.func.isRequired,
    children: PropTypes.node,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

TopMenuItem.defaultProps = {
    children: null,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
};

export default connectFela(stylesAll)(TopMenuItem);
