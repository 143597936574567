import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { connect, FelaComponent } from 'react-fela';

import * as componentStyles from './WindowHeader.styles';
import { IconButton } from '../Button';

import iconMinimize from '../../images/icons/minimize.svg';
import iconStretch from '../../images/icons/stretch.svg';
import iconUnstretch from '../../images/icons/unstrech.svg';
import iconClose from '../../images/icons/close.svg';

const WindowHeader = ({
    styles,
    title,
    titleKey,
    index,
    itemsCount,
    onMaximizeWindow,
    extraComponent,
    minimizeWindow,
    closeWindow,
    isMaximized,
    doubleBar,
    customTitle,
}) => (
    <FelaComponent style={componentStyles.container} doubleBar={doubleBar}>
        <h3 className={styles.title}>{titleKey ? <FormattedMessage id={titleKey} /> : title}</h3>
        {(itemsCount !== -1 || doubleBar) && (
            <FormattedMessage id="window.itemCount" values={{ count: itemsCount }}>
                {msg => (
                    <FelaComponent
                        as="span"
                        style={componentStyles.itemsCount}
                        doubleBar={doubleBar}
                        hasExtraComponent={Boolean(extraComponent)}
                    >
                        {msg}
                    </FelaComponent>
                )}
            </FormattedMessage>
        )}
        {customTitle && (
            <FelaComponent
                as="span"
                style={componentStyles.itemsCount}
                doubleBar={doubleBar}
                hasExtraComponent={Boolean(extraComponent)}
            >
                {customTitle}
            </FelaComponent>
        )}
        <div className={styles.rightMenu}>
            {extraComponent && (
                <FelaComponent style={componentStyles.extraComponent} doubleBar={doubleBar}>
                    {extraComponent()}
                </FelaComponent>
            )}
            <IconButton
                altId="window.icon.minimize"
                icon={iconMinimize}
                iconClassName={styles.icon}
                onClick={() => minimizeWindow(index)}
            />
            <IconButton
                altId="window.icon.stretch"
                icon={isMaximized ? iconUnstretch : iconStretch}
                iconClassName={styles.icon}
                onClick={onMaximizeWindow}
            />
            <IconButton
                altId="window.icon.close"
                icon={iconClose}
                iconClassName={styles.icon}
                onClick={() => closeWindow(index)}
                data-cy="close-window"
            />
        </div>
    </FelaComponent>
);

WindowHeader.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
        itemsCount: PropTypes.string.isRequired,
        rightMenu: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string,
    titleKey: PropTypes.string,
    customTitle: PropTypes.string,
    index: PropTypes.number.isRequired,
    itemsCount: PropTypes.number,
    onMaximizeWindow: PropTypes.func.isRequired,
    closeWindow: PropTypes.func.isRequired,
    minimizeWindow: PropTypes.func.isRequired,
    extraComponent: PropTypes.func,
    isMaximized: PropTypes.bool.isRequired,
    doubleBar: PropTypes.bool,
};

WindowHeader.defaultProps = {
    itemsCount: 0,
    title: '',
    titleKey: '',
    customTitle: '',
    extraComponent: undefined,
    doubleBar: true,
};

export default connect(componentStyles)(WindowHeader);
