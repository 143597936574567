export const wrapper = ({ theme }) => ({
    background: theme.colors.darkBlue,
    width: 270,
    maxWidth: '100%',
    margin: '40px auto',
    height: 8,
});

export const progress = ({ theme, percentage }) => ({
    display: 'block',
    height: '100%',
    width: `${percentage}%`,
    backgroundColor: theme.colors.white,
});
