import React from 'react';
import { PropTypes } from 'prop-types';
import { FelaComponent } from 'react-fela';
import IframeResizer from 'iframe-resizer-react';
import * as componentStyles from './IFrame.style';

const IFrame = ({ data, height }) => {
    return (
        <FelaComponent style={componentStyles.container} height={height}>
            <IframeResizer
                checkOrigin={false}
                sizeHeight
                autoResize
                bodyMargin="0"
                bodyPadding="0"
                scrolling="yes"
                src={data.url}
                style={{ width: '1px', minWidth: '100%', minHeight: `${height}px` }}
            />
        </FelaComponent>
    );
};

IFrame.propTypes = {
    data: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
    height: PropTypes.number.isRequired,
};

export default IFrame;
