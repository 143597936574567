import config from '../../config';

const { height, heightMobile } = config.navbar;

const corner = {
    width: '16px',
    height: '48px',
    borderStyle: 'solid',
    position: 'absolute',
    borderWidth: '0',
};

const blockDefault = bgColor => ({
    backgroundColor: bgColor,
    height: 2,
    width: 2,
});

export const header = ({ theme }) => ({
    height,
    zIndex: 2,
    maxWidth480: {
        height: heightMobile,
    },
    width: '100%',
    position: 'relative',
    padding: '0 10px',
});
export const headerInner = ({ theme }) => ({
    borderWidth: 0,
    borderTopWidth: 2,
    borderBottomWidth: 6,
    borderStyle: 'solid',
    borderColor: theme.colors.blue,
    height: '100%',
    float: 'left',
    width: '100%',
});
export const leftCorner = ({ theme }) => ({
    ...corner,
    borderColor: theme.colors.blue,
    borderTopWidth: 2,
    left: 0,
    top: 0,
});
export const rightCorner = ({ theme }) => ({
    ...corner,
    borderColor: theme.colors.blue,
    borderTopWidth: 0,
    borderBottomWidth: 6,
    right: 0,
    bottom: 0,
    height,
    maxWidth480: {
        height: heightMobile,
    },
});
export const line = ({ theme }) => ({
    height: height - 12,
    maxWidth480: {
        height: heightMobile - 12,
    },
    width: 2,
    backgroundColor: theme.colors.blue,
});
export const lineRight = ({ theme }) => ({
    height: height - 12,
    maxWidth480: {
        height: heightMobile - 12,
    },
    width: 6,
    float: 'right',
    backgroundColor: theme.colors.blue,
});
export const blockLeftOne = ({ theme }) => ({
    ...blockDefault(theme.colors.blue),
    marginLeft: 2,
});
export const blockLeftTwo = ({ theme }) => ({
    ...blockDefault(theme.colors.blue),
    marginLeft: 4,
});
export const blockLeftThree = ({ theme }) => ({
    ...blockDefault(theme.colors.blue),
    marginLeft: 6,
    width: 4,
});
export const blockLeftFour = ({ theme }) => ({
    ...blockDefault(theme.colors.blue),
    marginLeft: 8,
});
export const blockRightOne = ({ theme }) => ({
    ...blockDefault(theme.colors.blue),
    marginLeft: 6,
});
export const blockRightTwo = ({ theme }) => ({
    ...blockDefault(theme.colors.blue),
    marginLeft: 8,
});
export const blockRightThree = ({ theme }) => ({
    ...blockDefault(theme.colors.blue),
    marginLeft: 10,
});
export const blockRightFour = ({ theme }) => ({
    ...blockDefault(theme.colors.blue),
    marginLeft: 10,
    width: 4,
});
