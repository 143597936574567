export const container = ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'left',
    width: '100%',
    height: '100%',
    flexWrap: 'wrap',
});

export const title = ({ theme }) => ({
    fontSize: 18,
    lineHeight: '22px',
    wordBreak: 'break-all',
    color: theme.colors.black,
    margin: '16px 0 8px',
    fontWeight: 600,
});
