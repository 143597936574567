import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const secondsToTime = secs => {
    if (!secs || Number.isNaN(secs)) {
        return false;
    }
    secs = parseInt(secs, 10);
    const hours = Math.floor(secs / (60 * 60));
    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);
    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);
    return { hours, minutes, seconds };
};

const TimeCounter = ({ time, onFinish }) => {
    const [timeRemaining, setTimeRemaining] = useState(secondsToTime(time));
    useEffect(() => {
        if (!timeRemaining) return false;
        const interval = setInterval(() => {
            const newTimeRemaining = { ...timeRemaining };
            if (newTimeRemaining.seconds <= 0) {
                if (newTimeRemaining.minutes > 0) {
                    newTimeRemaining.minutes--;
                    newTimeRemaining.seconds = 59;
                } else if (newTimeRemaining.hours > 0) {
                    newTimeRemaining.hours--;
                    newTimeRemaining.minutes = 59;
                    newTimeRemaining.seconds = 59;
                }
            } else {
                newTimeRemaining.seconds--;
            }
            if (newTimeRemaining.seconds === 0) {
                onFinish();
            }
            setTimeRemaining(newTimeRemaining);
        }, 1000);
        return () => clearInterval(interval);
    }, [onFinish, timeRemaining]);

    return `
        ${String(timeRemaining.hours).padStart(2, '0')}:${String(timeRemaining.minutes).padStart(2, '0')}:${String(timeRemaining.seconds).padStart(2, '0')}
    `;
};

TimeCounter.propTypes = {
    time: PropTypes.number.isRequired,
    onFinish: PropTypes.func.isRequired,
};

export default TimeCounter;
