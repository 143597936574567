import React from 'react';
import { PropTypes } from 'prop-types';
import { FelaComponent } from 'react-fela';
import { FormattedMessage } from 'gatsby-plugin-intl';
import * as componentStyles from './Bin.styles';

const Bin = ({ data }) => (
    <FelaComponent style={componentStyles.container}>
        {data.map((project, i) => (
            <FelaComponent style={componentStyles.element} key={project.dataId}>
                <img src={project.icon} alt="" />
                <FormattedMessage id={project.titleKey}>
                    {txt => <FelaComponent style={componentStyles.title}>{txt}</FelaComponent>}
                </FormattedMessage>
            </FelaComponent>
        ))}
    </FelaComponent>
);

Bin.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
};

Bin.defaultProps = {
    data: [],
};

export default Bin;
