import React, { useCallback } from 'react';
import { FelaComponent } from 'react-fela';
import FelaProvider from './FelaProvider';
import Header from './Header/Header';
import * as stylesAll from './Layout.style';
import Dialog from './Dialog/Dialog';
import { DialogConsumer, DialogProvider } from '../context/DialogContext';
import SplashScreen from './SplashScreen/SplashScreen';
import { useDesktopReducer, useSystemReducer } from '../reducer';
import SEO from './Seo';
import AppContext from '../context/AppContext';
import Desktop from './Desktop/Desktop';
import { boot, setLoaded } from '../actions/system';
import { SHUTDOWN_TIME } from '../constants/animations';
import { resetToInitialState } from '../actions/desktop';

/* Support intl in older browsers */
if (typeof window !== 'undefined' && !window.Intl) {
    // eslint-disable-next-line global-require
    require('intl');
}

const App = () => {
    const [desktopState, desktopDispatch] = useDesktopReducer();
    const [systemState, systemDispatch] = useSystemReducer();

    /* Fill user state with desktop icons and etc. */
    const handleSplashScreenFinished = useCallback(() => {
        desktopDispatch(resetToInitialState());
        systemDispatch(setLoaded(true));
    }, [desktopDispatch, systemDispatch]);

    if (systemState.isGoingShutdown) {
        if (systemState.isRebooting) {
            setTimeout(systemDispatch, SHUTDOWN_TIME, boot());
        } else {
            setTimeout(systemDispatch, SHUTDOWN_TIME, setLoaded(false));
        }
        return (
            <>
                <p>System is going to shutdown...</p>
                {systemState.isRebooting ? (
                    <p>system is rebooting</p>
                ) : (
                    <p>system will be permanently shutdown, sorry</p>
                )}
            </>
        );
    }

    if (systemState.isBooting === false && systemState.isLoaded === false) {
        return <p>System has been permanently shutdown!</p>;
    }

    return (
        <FelaProvider>
            <SEO />
            <FelaComponent style={stylesAll.appWrapper}>
                <AppContext.Provider value={{ desktopState, desktopDispatch, systemState, systemDispatch }}>
                    {systemState.isBooting ? (
                        <SplashScreen onLoaded={handleSplashScreenFinished} withSystemInfo />
                    ) : (
                        <>
                            <DialogProvider>
                                <Header />
                                <FelaComponent style={stylesAll.contentWrapper}>
                                    <main>
                                        <AppContext.Consumer>
                                            {context => (
                                                <Desktop
                                                    state={context.desktopState}
                                                    dispatch={context.desktopDispatch}
                                                />
                                            )}
                                        </AppContext.Consumer>
                                    </main>
                                    <DialogConsumer>
                                        {({
                                            dialogActive,
                                            dialogText,
                                            dialogSuccess,
                                            dialogCancel,
                                            dialogClose,
                                            dialogTimer,
                                            dialogType,
                                        }) => (
                                            <Dialog
                                                isActive={dialogActive}
                                                messageId={dialogText}
                                                onClose={dialogClose}
                                                onCancel={dialogCancel}
                                                onSuccess={dialogSuccess}
                                                dialogTimer={dialogTimer}
                                                type={dialogType}
                                            />
                                        )}
                                    </DialogConsumer>
                                </FelaComponent>
                            </DialogProvider>
                        </>
                    )}
                </AppContext.Provider>
            </FelaComponent>
        </FelaProvider>
    );
};

export default App;
