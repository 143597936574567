import React from 'react';
import { PropTypes } from 'prop-types';
import { FelaComponent } from 'react-fela';
import ReactHtmlParser from 'react-html-parser';
import * as styles from './Editor.style';

const Editor = ({ data }) => {
    return (
        <FelaComponent style={styles.container}>
            {({ className }) => <div className={className}>{ReactHtmlParser(data)}</div>}
        </FelaComponent>
    );
};

Editor.propTypes = {
    data: PropTypes.string.isRequired,
};

export default Editor;
