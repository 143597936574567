export const container = ({ theme }) => ({
    width: theme.icons.width,
    height: theme.icons.height,
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 'auto',
    background: 'none',
    border: 'none',
    outline: 0,
});

export const title = ({ isFocused }) => ({
    maxWidth: '100%',
    wordBreak: 'break-all',
    padding: 0,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '22px',
    ...(isFocused && {
        '>span': {
            backgroundColor: 'cyan',
        }
    }),
});

export const image = ({ theme }) => ({
    width: theme.icons.widthSmall,
    height: theme.icons.heightSmall,
    marginBottom: '1rem',
});

export const icon = ({ theme, isDragging, isSubMenuOpened, isOnRejectedPos }) => ({
    zIndex: isDragging || isSubMenuOpened ? 3 : 2,
    ...(isOnRejectedPos && {
        backgroundColor: '#ff000021',
    }),
});
