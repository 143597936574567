import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';

const useProjectsQuery = () => {
    const data = useStaticQuery(graphql`
        query {
            allProjectsJson {
                edges {
                    node {
                        id
                        projects {
                            title
                            description
                            repoUrl
                            iconName
                        }
                    }
                }
            }
        }
    `);

    return get(data, ['allProjectsJson', 'edges'], [])
        .map(edge => edge && edge.node)
        .reduce((acc, node) => {
            acc[node.id] = {};

            Object.keys(node).forEach(key => {
                if (key !== 'id') {
                    acc[node.id] = { ...acc[node.id], [key]: node[key] };
                }
            });

            return acc;
        }, {});
};

export default useProjectsQuery;
