import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import { connect as connectFela } from 'react-fela';
import twitterShare from '../../images/twitter-btn.svg';
import * as stylesAll from "./TwitterShare.style";


const shareParamsUrl = ((params) => {
    let url = "http://twitter.com/share?";
    // eslint-disable-next-line guard-for-in
    for (const key in params) {
        url += `&${  key  }=${encodeURI(params[key])}`
    }
    return url;
})({
    url: "https://wtfisackee.dev/",
    text: "I love it!",
    via: "AckeeCZ",
    hashtags: "ackeecz,opensource"
});

const popupWidth = 500;
const popupHeight = 450;

const TwitterShare = ({ styles }) => {
    const handleClick = useCallback(() => {
        const left = window.innerWidth / 2 - popupWidth / 2;
        const top = window.innerHeight / 2 - popupHeight / 2;
        window.open(shareParamsUrl, 'popup', `left=${left},top=${top},width=${popupWidth},height=${popupHeight},personalbar=0,toolbar=0,scrollbars=0,resizable=0`);
    }, []);
    return (
        <section className={styles.wrapper}>
            <button onClick={handleClick} className={styles.button} type="button">
                <img src={twitterShare} alt="Twitter share button" />
            </button>
        </section>
    );
};

TwitterShare.propTypes = {
    styles: PropTypes.shape({
        wrapper: PropTypes.string,
        button: PropTypes.string
    }).isRequired
};

export default connectFela(stylesAll)(TwitterShare);
