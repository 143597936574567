export default {
    window: {
        defaultWidth: 800,
        defaultHeight: 600,
        width: 800,
        height: 600,
        navbar: {
            height: 46,
            fullHeight: 52,
        },
    },
    icons: {
        width: 150,
        height: 150,
    },
    navbar: {
        height: 56,
        heightMobile: 100,
        iconHeight: 28,
        scrollStep: 20,
        minScrollbarHeight: 28,
        scrollbarWidth: 30,
    },
    submenu: {
        minWidth: 160,
        minHeight: 60,
    },
};
