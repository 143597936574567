import {graphql, useStaticQuery} from 'gatsby';
import {get} from 'lodash';
import {useMemo} from "react";

const useSearchQuery = () => {

    const data = useStaticQuery(graphql`
        query {
            allProjectsJson {
                edges {
                    node {
                        id
                        projects {
                            title
                            description
                            repoUrl
                            iconName
                        }
                    }
                }
            }
        }
    `);

    return useMemo(() => (
       get(data, ['allProjectsJson', 'edges'], []).map(edge => edge && edge.node)
    ), [data]);
};

export default useSearchQuery;
