import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';

const useEditorsQuery = () => {
    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark {
                edges {
                    node {
                        html
                        frontmatter {
                            id
                        }
                    }
                }
            }
        }
    `);

    // eslint-disable-next-line no-console
    return get(data, ['allMarkdownRemark', 'edges']).reduce((acc, edge) => {
        acc[edge.node.frontmatter.id] = edge.node.html;
        return acc;
    }, {});
};

export default useEditorsQuery;
