import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import * as stylesAll from './DesktopSubMenuRightClick.style';
import { windowTop, windowLeft, windowRight, windowBottom } from '../Window/Window.style';

const DesktopSubMenu = ({ position, items, selectedId, onChange }) => (
    <FelaComponent style={stylesAll.subNav} position={position}>
        <FelaComponent style={windowTop} />
        <FelaComponent style={windowLeft} />
        <FelaComponent style={windowRight} />
        <FelaComponent style={windowBottom} />

        <FelaComponent style={stylesAll.desktopSubMenuWrapper}>
            {items
                .filter(item => item.id !== selectedId)
                .map(subItem => (
                    <FelaComponent as="li" key={`desktopSubMenu-${subItem.id}`} style={stylesAll.subItem}>
                        <FelaComponent style={stylesAll.subItemLink}>
                            {subItemStyles => (
                                <button
                                    type="button"
                                    className={subItemStyles.className}
                                    onClick={e => {
                                        onChange(e, subItem);
                                    }}
                                >
                                    <FormattedMessage id={subItem.id} />
                                </button>
                            )}
                        </FelaComponent>
                    </FelaComponent>
                ))}
        </FelaComponent>
    </FelaComponent>
);

DesktopSubMenu.propTypes = {
    position: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }).isRequired,
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
    selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
};

DesktopSubMenu.defaultProps = {
    selectedId: -1,
};

export default DesktopSubMenu;
