export const container = ({ theme }) => ({
    flex: '0 0 25%',
    maxWidth: '25%',
    padding: '1rem',
    flexDirection: 'column',
    boxSizing: 'border-box',
    textAlign: 'center',
    fontSize: '0.9rem',
    border: 'none',
    background: 'none',
    display: 'block',
    textDecoration: 'none',
    color: theme.colors.black,
    maxWidth767: {
        flex: '0 0 50%',
        maxWidth: '50%',
    },
});

export const title = ({ theme }) => ({
    color: theme.colors.pink,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '22px',
    margin: '16px 0 8px',
});
