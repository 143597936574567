import config from '../config';

const calculateSubmenuPosition = (x, y) => {
    // Bottom bounds
    if (x + config.submenu.minWidth > window.innerWidth) {
        x -= config.submenu.minWidth;
    }
    if (y + config.submenu.minHeight > window.innerHeight - config.navbar.height) {
        y -= config.submenu.minHeight;
    }
    // Top bounds
    if (x < 0) {
        x = 0;
    }
    if (y < 0) {
        y = 0;
    }
    return { x, y };
};

export default calculateSubmenuPosition;
