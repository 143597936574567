import * as constants from '../constants/system';

export const setPlannedShutdown = () => ({
    type: constants.SYSTEM_SHUTDOWN,
});

export const setPlannedReboot = () => ({
    type: constants.SYSTEM_REBOOT,
});

export const setLoaded = loaded => ({
    type: constants.SYSTEM_LOADED,
    loaded,
});

export const boot = () => ({
    type: constants.SYSTEM_BOOT,
});
