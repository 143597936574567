import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect as connectFela } from 'react-fela';
import facebookShare from '../../images/facebook-btn.svg';
import * as stylesAll from './FacebookShare.style';

const shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwtfisackee.dev%2F';
const popupWidth = 600;
const popupHeight = 600;

const FacebookShare = ({ styles }) => {
    const handleClick = useCallback(() => {
        const left = window.innerWidth / 2 - popupWidth / 2;
        const top = window.innerHeight / 2 - popupHeight / 2;
        window.open(shareUrl, 'popup', `left=${left},top=${top},width=${popupWidth},height=${popupHeight},personalbar=0,toolbar=0,scrollbars=0,resizable=0`);
    }, []);

    return (
        <section className={styles.wrapper}>
            <button onClick={handleClick} className={styles.button} type="button">
                <img src={facebookShare} alt="Facebook share button" />
            </button>
        </section>
    );
};

FacebookShare.propTypes = {
    styles: PropTypes.shape({
        wrapper: PropTypes.string,
        button: PropTypes.string,
    }).isRequired,
};

export default connectFela(stylesAll)(FacebookShare);
