import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-fela';
import { get } from 'lodash';
import { FormattedMessage } from 'gatsby-plugin-intl';
import * as stylesAll from './Desktop.style';
import DesktopIcon from './Icons/DesktopIcon';
import Window from '../Window/Window';
import { useComponentSize, useDesktopIcon } from '../../hooks';
import useProjectsQuery from './Desktop.projects.query';
import useEditorsQuery from './Desktop.editors.query';
import * as windowTypes from '../../constants/icons';
import DesktopSubMenuRightClick from './DesktopSubMenuRightClick';
import config from '../../config';
import * as actions from '../../actions/desktop';
import BinIcon from './Icons/BinIcon';
import { DialogConsumer } from '../../context/DialogContext';
import useGalleriesQuery from './Desktop.galleries.query';

const GRID_MENU_ITEMS = [{ id: 'desktop.settings.grid.enable' }, { id: 'desktop.settings.grid.disable' }];

const Desktop = ({ styles, state, dispatch }) => {

    const {
        onDragStop,
        isPlaceAvailable,
        repairIconsGrid,
        removeIcon,
        openWindow,
        isBinAnimating,
        emptyBin,
    } = useDesktopIcon(dispatch, state.icons);

    const [size, desktopRef] = useComponentSize();
    const projects = useProjectsQuery();
    const galleries = useGalleriesQuery();
    const editors = useEditorsQuery();

    return (
        <div className={styles.container} ref={desktopRef}>
            <DesktopSubMenuRightClick
                items={GRID_MENU_ITEMS}
                selectedId={state.gridEnabled ? GRID_MENU_ITEMS[0].id : GRID_MENU_ITEMS[1].id}
                onChange={(e, item) => {
                    if (item === GRID_MENU_ITEMS[0]) {
                        dispatch(actions.gridSettings(true));
                        repairIconsGrid();
                    } else {
                        dispatch(actions.gridSettings(false));
                    }
                }}
            />
            {state.icons.map(({ dataId, titleKey, icon }, index) => {
                if (state.icons[index].isDeleted) {
                    return false;
                }
                const iconProps = {
                    ...{
                        titleKey,
                        icon,
                        detail: state.icons[index],
                        dragGrid: state.gridEnabled ? [config.icons.width, config.icons.height] : undefined,
                        size: {
                            width: config.icons.width,
                            height: config.icons.height,
                        },
                        enableResizing: false,
                        isPlaceAvailable,
                        onDragStop: (e, pos) => onDragStop(e, pos, index, state.gridEnabled),
                        openWindow: (e) => openWindow(index, dataId, titleKey, e.clientX, e.clientY),
                    },
                };
                if (dataId === 'bin') {
                    return (
                        <DialogConsumer key={titleKey}>
                            {({ openDialog }) => (
                                <BinIcon
                                    openDialog={openDialog}
                                    isFilled={state.icons.some(el => el.isDeleted)}
                                    isDeleting={isBinAnimating}
                                    emptyBin={emptyBin}
                                    {...iconProps}
                                />
                            )}
                        </DialogConsumer>
                    );
                }
                return <DesktopIcon removeIcon={() => removeIcon(index, dataId)} key={titleKey} {...iconProps} />;
            })}
            {state.windows.map((window, index) => {
                if (window.isMinimized) {
                    return false;
                }
                return (
                    <Window
                        {...{
                            x: window.x,
                            y: window.y,
                            isFocused: window.isFocused,
                            data: (() => {
                                switch (window.type) {
                                    case windowTypes.ICON_TYPE_PROJECTS:
                                        return get(projects, [window.dataId, 'projects']);
                                    case windowTypes.ICON_TYPE_GALLERY:
                                        return get(galleries, [window.dataId, 'photos']);
                                    case windowTypes.ICON_TYPE_EDITOR:
                                        return get(editors, [window.dataId]);
                                    case windowTypes.ICON_TYPE_BIN:
                                        return state.icons.filter(icon => icon.isDeleted);
                                    case windowTypes.ICON_TYPE_SEARCH:
                                        return window.data;
                                    default:
                                        return [];
                                }
                            })(),
                            key: window.titleKey,
                            index,
                            desktopSize: size,
                            focusWindow: () => dispatch(actions.focusWindow(index)),
                            updateWindow: obj => dispatch(actions.updateWindow(index, obj)),
                            closeWindow: () => dispatch(actions.closeWindow(window.dataId)),
                            minimizeWindow: () => dispatch(actions.minimizeWindow(index)),
                            maximizeWindow: () => dispatch(actions.maximizeWindow(index)),
                            doubleBar: Boolean(window.doubleBar),
                            ...(window.dataId === 'bin' && {
                                emptyBin,
                            }),
                            ...window,
                        }}
                    />
                );
            })}
            <div className={styles.minimizedWindowsWrapper}>
                {state.windows.map((window, index) => {
                    if (!window.isMinimized) {
                        return false;
                    }
                    return (
                        <button
                            className={styles.openButton}
                            type="button"
                            key={window.titleKey}
                            onClick={() => dispatch(actions.maximizeWindow(index))}
                        >
                            <FormattedMessage id={window.titleKey}>
                                {txt => <span className={styles.openButtonText}>{txt}</span>}
                            </FormattedMessage>
                            <span className={styles.cross} />
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

Desktop.propTypes = {
    state: PropTypes.shape({
        windows: PropTypes.array,
        icons: PropTypes.array,
        gridEnabled: PropTypes.bool,
        windowsMaxZIndex: PropTypes.number,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    styles: PropTypes.shape({
        container: PropTypes.string,
        minimizedWindowsWrapper: PropTypes.string,
        openButton: PropTypes.string,
        cross: PropTypes.string,
        openButtonText: PropTypes.string,
    }).isRequired,
};

export default connect(stylesAll)(Desktop);
